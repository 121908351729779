/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Input, message, Form, Spin, Divider, Space } from 'antd';
import Icon from '@ant-design/icons';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';

import {
  hideMessage,
  loadUserProfileMeApi,
  showAuthLoader,
  signInWithEmailPasswordThunk,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSignIn,
  userSignInAzureSuccess,
  userTwitterSignIn,
} from '../appRedux/actions/Auth';

import IntlMessages from 'util/IntlMessages';
import CircularProgress from 'components/CircularProgress/index';
import TwitterOutlined from '@ant-design/icons/lib/icons/TwitterOutlined';
import GithubOutlined from '@ant-design/icons/lib/icons/GithubOutlined';
import FacebookOutlined from '@ant-design/icons/lib/icons/FacebookOutlined';
import GoogleOutlined from '@ant-design/icons/lib/icons/GoogleOutlined';
import { useForm } from 'antd/lib/form/Form';
import { LOGIN_TYPE_AZURE, LOGIN_TYPE_PASSWORD, VERSION_APP, passwordHardCode, usernameHardCode } from '../app.const';
import { isLocalhost, setLoginType } from '../util/helper';
import { useMsal } from '@azure/msal-react';
// import { VERSION_APP } from '../app.const';

const SignIn = (props) => {
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const location = useLocation();

  const { loader, alertMessage, showMessage, authUser, ssoToken, loginType } = useSelector(({ auth }) => auth);
  const FormItem = Form.Item;
  const [form] = Form.useForm();
  let username = isLocalhost() ? usernameHardCode : '';
  let password = isLocalhost() ? passwordHardCode : '';
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const isEnableSSO = true; // !!localStorage.getItem('isEnableSSO') || isLocalhost();

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const code = searchParams.get('code');
    const error = searchParams.get('error');
    // console.log(`query params `, { code, error, searchParams });
    return function () {};
  }, []);

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 1000);
    }
    return function () {};
  }, [showMessage]);

  useEffect(() => {
    if (authUser && location.pathname == '/signin') {
      // const { idToken, accessToken } = ssoToken || {};
      message.success('Login successfully');
      history.push('/main');
      // props.loadUserProfileMeThunk({
      //   idToken,
      //   accessToken,
      //   loginType,
      //   authUser,
      //   cb: (err, data) => {
      //     setLoading(false);
      //     if (!err && data) {
      //     } else {
      //       if (err || !data) {
      //       }
      //     }
      //   },
      // }); //thunk action
    }
    return function () {};
  }, [authUser]);

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onFinishFormLogin = (values) => {
    // console.log("onFinish:", values);
    setLoginType(LOGIN_TYPE_PASSWORD);
    setLoading(true);
    // console.log("submit login on form finish", values);
    if (isLocalhost()) {
      if (values && values.username == 'admin') {
        // {
        //   "username": "admin",
        //   "password": "transcendteqAdminUser"
        // }
        // thông tin admin user login nha, admin user này chỉ dc quyền xem logs + search user nha @Nguyên
        values.password = 'transcendteqAdminUser';
      }
    }
    props.signInWithEmailPasswordThunk({
      ...values,
      cb: (err, data) => {
        console.log(`cb after submit `, err, data);
        setLoading(false);
        if (!err && data) {
        }
      },
    }); //thunk action
  };

  const callLoginSamlMS = async () => {
    setLoginType(LOGIN_TYPE_AZURE);
    setLoading(true);
    try {
      let authenResult = await instance.loginPopup({
        scopes: ['user.read', 'email', 'openid', 'profile'],
      });
      console.log(`call login ms success`, authenResult);
      const { idToken, accessToken } = authenResult;

      let reduxLoginData = {
        user: {
          ...authenResult.account,
          email: authenResult.account.email || authenResult.idTokenClaims.email,
          username: authenResult.account.username,
          name: authenResult.account.idTokenClaims.name,
        },
        token: accessToken,
        accessToken: accessToken,
        idToken: idToken,
        loginType: LOGIN_TYPE_AZURE,
      };
      let profileMeResult = await loadUserProfileMeApi({
        accessToken,
        idToken,
        loginType: LOGIN_TYPE_AZURE,
      });
      if (profileMeResult) {
        console.log(`call redux login success, reduxLoginData= `, reduxLoginData);
        props.userSignInAzureSuccess(reduxLoginData);
      }
    } catch (error) {
      console.error(`error login popup`, error);
      message.error(error && error.message ? JSON.stringify(error.message) : JSON.stringify(error));
    }
    setLoading(false);
  };

  const loadingElement = <Spin className="spinner-login-loading mr-2" size="small" />;
  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          {/* <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              <img src={"https://via.placeholder.com/272x395"} alt="Neature" />
            </div>
            <div className="gx-app-logo-wid">
              <h1>
                <IntlMessages id="app.userAuth.signIn" />
              </h1>
              <p>
                <IntlMessages id="app.userAuth.bySigning" />
              </p>
              <p>
                <IntlMessages id="app.userAuth.getAccount" />
              </p>
            </div>
            <div className="gx-app-logo">
              <img alt="example" src={require("assets/images/logo.png")} />
            </div>
          </div> */}
          <div className="gx-app-login-content">
            <Form
              layout="vertical"
              form={form}
              name="basic"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinishFormLogin}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
            >
              <h2 className="text-center">Sign In</h2>
              <FormItem htmlFor="username" label={'Username'} initialValue={username} rules={[{ required: true, message: 'Please input your Username!' }]} name="username">
                {/* <label>Username</label> */}
                <Input placeholder="Username" autocomplete />
              </FormItem>
              <FormItem htmlFor="password" label={'Password'} initialValue={password} rules={[{ required: true, message: 'Please input your Password!' }]} name="password">
                {/* <label>Password</label> */}
                <Input type="password" placeholder="Password" autocomplete />
              </FormItem>

              {/* <FormItem>
								<Checkbox>
									<IntlMessages id="appModule.iAccept" />
								</Checkbox>
								<span className="gx-signup-form-forgot gx-link">
									<IntlMessages id="appModule.termAndCondition" />
								</span>
							</FormItem> */}

              <FormItem className="text-center">
                <Button type="primary" className="gx-mb-0" htmlType="submit" disabled={loading}>
                  {loading ? loadingElement : <i className="fa fa-sign-in mr-2"></i>}
                  <IntlMessages id="app.userAuth.signIn" />
                </Button>
                {/* <span>
									<IntlMessages id="app.userAuth.or" />
								</span>{" "}
								<Link to="/signup">
									<IntlMessages id="app.userAuth.signUp" />
								</Link> */}
              </FormItem>
              <div className="gx-flex-row" style={{ justifyContent: 'center', marginBottom: '30px' }}>
                {isEnableSSO && (
                  <Space>
                    <span>or connect with</span>
                    <span>
                      <img
                        src="/images/login-with-ms.png"
                        className="pointer"
                        onClick={() => {
                          callLoginSamlMS();
                          // dispatch(showAuthLoader());
                          // dispatch(userGoogleSignIn());
                        }}
                        style={{ width: '25px' }}
                      />
                    </span>
                  </Space>
                )}

                {/* <ul className="gx-social-link">
									<li>
										<GoogleOutlined
											onClick={() => {
												dispatch(showAuthLoader());
												dispatch(userGoogleSignIn());
											}}
										/>
									</li>
									<li>
										<FacebookOutlined
											onClick={() => {
												dispatch(showAuthLoader());
												dispatch(userFacebookSignIn());
											}}
										/>
									</li>
									<li>
										<GithubOutlined
											onClick={() => {
												dispatch(showAuthLoader());
												dispatch(userGithubSignIn());
											}}
										/>
									</li>
									<li>
										<TwitterOutlined
											onClick={() => {
												dispatch(showAuthLoader());
												dispatch(userTwitterSignIn());
											}}
										/>
									</li>
								</ul> */}
              </div>
            </Form>
            <Divider>{VERSION_APP}</Divider>
          </div>

          {loader ? (
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          ) : null}
          {showMessage ? message.error(String(alertMessage).toString()) : null}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return state;
}
export default connect(mapStateToProps, {
  signInWithEmailPasswordThunk: signInWithEmailPasswordThunk,
  userSignInAzureSuccess: userSignInAzureSuccess,
})(SignIn);
