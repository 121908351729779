/* eslint-disable */
import React from 'react';
import { Route, Switch } from 'react-router-dom';

// import Components from "./components/index";
// import CustomViews from "./customViews/index";
// import Extensions from "./extensions/index";
// import ExtraComponents from "./extraComponents/index";
// import InBuiltApps from "./inBuiltApps/index";
// import SocialApps from "./socialApps/index";
// import Main from "./main/index";
import homePage from './../pages/homePage';
import templatePage from './../pages/templatePage';
import logPage from './../pages/logPage';
import userPage from './../pages/userPage';
// import Documents from "./documents/index";

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}main`} exact={true} component={homePage} />
      <Route path={`${match.url}template`} exact={true} component={templatePage} />
      <Route path={`${match.url}log`} exact={true} component={logPage} />
      <Route path={`${match.url}user`} exact={true} component={userPage} />
      {/* <Route path={`${match.url}components`} component={Components}/>
      <Route path={`${match.url}custom-views`} component={CustomViews}/>
      <Route path={`${match.url}extensions`} component={Extensions}/>
      <Route path={`${match.url}extra-components`} component={ExtraComponents}/>
      <Route path={`${match.url}in-built-apps`} component={InBuiltApps}/>
      <Route path={`${match.url}social-apps`} component={SocialApps}/>
      <Route path={`${match.url}documents`} component={Documents}/> */}
    </Switch>
  </div>
);

export default App;
