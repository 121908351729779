/* eslint-disable */
import React from 'react';
import { Col, Row, DatePicker, Space, Modal, Menu, Dropdown, Icon, message } from 'antd';

import { Area, AreaChart, Line, LineChart, ResponsiveContainer, Tooltip } from 'recharts';
import ChartCard from 'components/dashboard/Crypto/ChartCard';
import Auxiliary from 'util/Auxiliary';
import Portfolio from 'components/dashboard/Crypto/Portfolio';
import BalanceHistory from 'components/dashboard/Crypto/BalanceHistory';
import SendMoney from 'components/dashboard/Crypto/SendMoney';
import RewardCard from 'components/dashboard/Crypto/RewardCard';
import CurrencyCalculator from 'components/dashboard/Crypto/CurrencyCalculator';
import CryptoNews from 'components/dashboard/Crypto/CryptoNews';
import DownloadMobileApps from 'components/dashboard/Crypto/DownloadMobileApps';
import OrderHistory from 'components/dashboard/Crypto/OrderHistory';
import { increamentData, lineData } from '../routes/main/Metrics/data';
import * as _ from 'lodash';
import * as uuid from 'uuid';
import { createTemplatePromise, deleteTemplatePromise, listTemplatePromise, updateTemplatePromise } from '../appRedux/actions/Template';
import BlockUi from 'react-block-ui';
import { COLUMNS_KEY_TASK, COLUMNS_KEY_DATE_OF_WEEK, COLUMNS_KEY_LABEL_TASK, TABLE_LIST_COLUMN_TASK, HARDCODE_ACT } from '../app.const';
import {
  formatDetailsErrorArrayFromApi,
  openNotification,
  removeFieldNotAllowUpdateTemplate,
  removeFieldsNotAllowAddTemplate,
  removeFieldsNotAllowCreate,
  removeFieldsNotAllowUpdate,
  removeNullValue,
} from '../util/helper';
import ScannerComponent from '../components/Scanner/Scanner';
const ACTION_CREATE = 'ACTION_CREATE';
const ACTION_UPDATE = 'ACTION_UPDATE';
const ACTION_DELETE = 'ACTION_DELETE';
const KEYS_CHECK_PAYLOAD_BEFORE_SUBMIT = [
  // "act",
  // "plant",
  // "recCCtr",
  // "recOrder",
  // "sendCostCenter"
];
const TABLE_LIST_COLUMN_TEMPLATE = [...TABLE_LIST_COLUMN_TASK].map((r) => {
  return {
    ...r,
    isDisableInput: false,
  };
});
export default class TemplatePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isExpandRight: false,
      listTemplate: [],
      currentTemplate: null,
      blockUi: false,
      showRightBlock: false,
      columnDisplay: Object.keys(COLUMNS_KEY_TASK).map((k) => {
        return {
          columnKey: COLUMNS_KEY_TASK[k],
          isShow: true,
        };
      }),
      tmpColumnDisplay: [],
      action: '',
      showModalScanSignal: false,
    };
  }
  setStateAsync(stateUpdate) {
    return new Promise((resolve) => {
      this.setState(stateUpdate, () => resolve());
    });
  }
  startBlockUi = () => {
    this.setState({
      blockUi: true,
    });
  };
  stopBlockUi = () => {
    this.setState({
      blockUi: false,
    });
  };
  componentDidMount = async () => {
    this.startBlockUi();
    await this.getTemplateData();
    this.stopBlockUi();
    //TEST
    // setTimeout(()=>{
    //   this.scanCompleteGotNewTemplate({
    //     "act": null,
    //     "actType": "3032U",
    //     "network": "NOPOST",
    //     "plant": null,
    //     "recCCtr": null,
    //     "recOrder": null,
    //     "wbsElement": "JBEALTSTCER_01.01",
    //     "sendCostCenter": null,
    //     "workCenter": "011HWEC",
    //     [COLUMNS_KEY_TASK.TocsID]: 'TocsID',
    //     [COLUMNS_KEY_TASK.AAType]: 'AAType'
    //   });
    // }, 1000);
  };
  getTemplateData = async () => {
    try {
      let res = await listTemplatePromise({});
      console.log('get templates ', res);
      this.setState({
        listTemplate: res,
      });
    } catch (error) {
      console.log(error);
      message.error(error.message);
    }
  };
  isShowColumn = (columnKey) => {
    let { columnDisplay } = this.state;
    let find = _.find(columnDisplay, { columnKey, isShow: true });
    return !!find;
  };
  isShowColumnDateOfWeek = (columnKey) => {
    return true;
  };
  submitDeleteTemplate = async (template, idx) => {
    Modal.confirm({
      title: 'Confirm !',
      content: 'Do you want to delete this template ?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: async () => {
        this.startBlockUi();
        try {
          let id = encodeURIComponent(template.id);
          let res = await deleteTemplatePromise(id);
          message.success('Delete template successfully');
        } catch (error) {
          console.log(error);
          message.error(error.message);
        }
        await this.getTemplateData();
        this.stopBlockUi();
      },
      onCancel: () => {},
    });
  };
  onActionUpsertTemplate = (action, template, idx) => {
    if (action == ACTION_UPDATE) {
      this.setState({
        action: ACTION_UPDATE,
        currentTemplate: _.cloneDeep(template),
      });
    } else if (action == ACTION_CREATE) {
      this.setState({
        action: ACTION_CREATE,
        currentTemplate: _.cloneDeep(template),
      });
    }
  };
  onChangeInputCurrentTemplate = async (e, aTemplate, idx, key) => {
    let currentTemplate = this.state.currentTemplate;
    currentTemplate[key] = e.target.value;
    this.setState({
      currentTemplate,
    });
  };
  closeAllModal = async () => {
    Modal.destroyAll();
    await this.setStateAsync({
      action: '',
      // showModalDisplayColumn: false,
      // showModalAddToTemplate: false,
      // showModalDeleteTask: false,
      // showModalUseTemplate: false,
      currentTemplate: null,
      showModalScanSignal: false,
    });
  };
  checkIsHaveChangeData = (editedObject, objB) => {
    console.log('editedObject', editedObject);
    let isNew = editedObject.isNew;
    let haveChange = false;
    let keys = Object.keys(editedObject);
    for (let k of keys) {
      if (k == 'isNew' || k == 'id') continue;
      if (typeof editedObject[k] != 'function') {
        if (editedObject[k] !== objB[k] || (isNew && editedObject[k])) {
          haveChange = true;
        }
      }
    }
    return haveChange;
  };
  showNotificationValidateTemplate = (error) => {
    let errorsArr = formatDetailsErrorArrayFromApi(error);
    openNotification({
      type: 'error',
      title: 'Validate fail',
      description: (
        <div>
          {errorsArr.map((r, idx) => {
            return (
              <span className="d-block" key={idx}>
                {r.message}
              </span>
            );
          })}
        </div>
      ),
    });
  };
  setRowError = async (error) => {
    // neu pass vao array thi su dung luon
    let errorsArr = Array.isArray(error) && error.length > 0 ? error : formatDetailsErrorArrayFromApi(error);
    let currentTemplate = this.state.currentTemplate;
    let listTemplate = this.state.listTemplate;
    listTemplate = listTemplate.map((r) => {
      delete r.isError;
      delete r.errors;
      return r;
    });
    let findIdx = _.findIndex(listTemplate, { id: currentTemplate.id });
    console.log('find idx', findIdx);
    if (findIdx >= 0) {
      listTemplate[findIdx].isError = true;
      listTemplate[findIdx].errors = errorsArr;
      await this.setStateAsync({
        listTemplate,
      });
    }
  };
  checkPayloadBeforeSubmit = (payload) => {
    // payload = removeNullValue(payload);
    let keys = KEYS_CHECK_PAYLOAD_BEFORE_SUBMIT;
    let errorsArr = [];
    for (let k of keys) {
      if (!payload[k]) {
        errorsArr.push({
          key: k,
          message: `Value of "${k}" is invalid`,
        });
      }
    }
    if (errorsArr.length > 0) {
      openNotification({
        type: 'error',
        title: 'Validate fail',
        description: (
          <div>
            {errorsArr.map((r, idx) => {
              return (
                <span className="d-block" key={idx}>
                  {r.message}
                </span>
              );
            })}
          </div>
        ),
      });
      // this.setRowError(errorsArr);
      return {
        success: false,
        errors: errorsArr,
      };
    }
    return {
      success: true,
      errors: null,
    };
  };
  submitSaveEditOnOneRow = async () => {
    let currentTemplate = Object.assign({}, this.state.currentTemplate);
    // let listTemplate = this.state.listTemplate;
    let isSubmitNew = currentTemplate.isNew;
    if (isSubmitNew) {
      let paramCreateUpdate = {
        ...currentTemplate,
      };
      console.log('paramCreateUpdate ', paramCreateUpdate);
      let paramCreate = removeFieldsNotAllowAddTemplate(paramCreateUpdate);
      paramCreate = removeNullValue(paramCreate);
      let rsCheckPayload = this.checkPayloadBeforeSubmit(paramCreate);
      if (rsCheckPayload.errors) {
        this.setRowError(rsCheckPayload.errors); // set row error
      } else if (rsCheckPayload.success == true) {
        try {
          await createTemplatePromise({ templates: [paramCreate] });
          await this.getTemplateData();
          message.success('Create new template successfully');
          this.closeAllModal();
        } catch (error) {
          console.log(error);
          // message.error(error.message || JSON.stringify(error));
          this.showNotificationValidateTemplate(error);
          this.setRowError(error);
        }
      }
      return;
    } else {
      Modal.confirm({
        title: 'Update template',
        content: 'Do you want to update this template',
        onOk: async (e) => {
          let paramCreateUpdate = {
            ...currentTemplate,
          };
          console.log('paramCreateUpdate ', paramCreateUpdate);
          let paramUpdate = removeFieldNotAllowUpdateTemplate(paramCreateUpdate);
          paramUpdate = removeNullValue(paramUpdate);
          console.log('param update ', paramUpdate);
          let rsCheckPayload = this.checkPayloadBeforeSubmit(paramUpdate);
          if (rsCheckPayload.errors) {
            this.setRowError(rsCheckPayload.errors); // set row error
          } else if (rsCheckPayload.success == true) {
            try {
              await updateTemplatePromise(encodeURIComponent(paramCreateUpdate.id), paramUpdate);
              await this.getTemplateData();
              message.success('Update template successfully');
              this.closeAllModal();
            } catch (error) {
              console.log(error);
              // message.error(error.message || JSON.stringify(error));
              this.showNotificationValidateTemplate(error);
              this.setRowError(error);
            }
          }
        },
        onCancel: () => {
          // this.closeAllModal();
          Modal.destroyAll();
        },
      });
    }
  };
  closeEditOnOneRow = async (template, idx) => {
    let currentTemplate = this.state.currentTemplate;
    let listTemplate = this.state.listTemplate;
    let isNew = currentTemplate.isNew;
    let haveChange = this.checkIsHaveChangeData(currentTemplate, listTemplate[idx]);
    if (!haveChange) {
      if (currentTemplate.isNew) {
        listTemplate.splice(idx, 1);
        await this.setStateAsync({
          listTemplate,
          currentTemplate: null,
          action: '',
        });
      } else {
        // cancel update => reset origin data row
        await this.setStateAsync({
          currentTemplate: null,
          action: '',
        });
      }
      this.closeAllModal();
      return;
    }
    Modal.confirm({
      title: isNew ? 'Do you want to remove temporary template ?' : 'Do you want to discard change for this template ?',
      onOk: async (e) => {
        // new template => remove row
        if (currentTemplate.isNew) {
          listTemplate.splice(idx, 1);
          await this.setStateAsync({
            listTemplate,
            currentTemplate: null,
            action: '',
          });
        } else {
          // cancel update => reset origin data row
          await this.setStateAsync({
            currentTemplate: null,
            action: '',
          });
        }
        this.closeAllModal();
      },
      onCancel: (e) => {
        // this.closeAllModal();
        Modal.destroyAll();
      },
    });
  };
  clickAddNewTemplate = async (templateScaned = null) => {
    let listTemplate = this.state.listTemplate;
    if (listTemplate.filter((r) => r.isNew).length >= 1) {
      message.error('New template already added. Please review and submit it ! ');
      return;
    }
    let template = {
      isNew: true,
      id: uuid.v4(),
    };
    Object.keys(COLUMNS_KEY_TASK).forEach((k) => {
      template[k] = '';
    });
    template = {
      ...template,
      [COLUMNS_KEY_TASK.Act]: HARDCODE_ACT,
      ...(templateScaned || {}),
      // [COLUMNS_KEY_TASK.AAType]: HARDCODE_AATYPE,
    };
    listTemplate = [template, ...listTemplate];
    await this.setStateAsync({
      listTemplate,
      currentTemplate: template,
      action: ACTION_CREATE,
    });
  };
  scanCompleteGotNewTemplate = async (taskScanned = {}) => {
    if (this.state.loading) return;
    await this.setStateAsync({
      loading: true,
    });
    try {
      await this.clickAddNewTemplate(taskScanned);
      await this.submitSaveEditOnOneRow();
    } catch (error) {}
    await this.setStateAsync({
      loading: false,
    });
    // Modal.confirm({
    //   title: "Confirm",
    //   content: "Scan complete, get a new template from scanner. Do you want to create ?",
    //   onOk: () => {
    //     this.submitSaveEditOnOneRow();
    //   },
    //   onCancel: () => {},
    // });
    // message.success(`Temp task has been added, please review and input hour in this task !`);
  };
  showModalScanSignalHandler = (v = true) => {
    if (v == true) {
      this.setState({
        showModalScanSignal: true,
      });
    } else {
      this.closeAllModal();
    }
  };

  render() {
    let { isExpandRight, showRightBlock, listTemplate, action, currentTemplate } = this.state;
    let rightCol = !isExpandRight ? 0 : 8;
    let leftCol = !isExpandRight ? 24 : 16;
    const isEditMode = !!currentTemplate && [ACTION_CREATE, ACTION_UPDATE].includes(action);

    return (
      <Auxiliary>
        <BlockUi blocking={this.state.blockUi}>
          <div className="template-page  ant-card">
            <div className=" ant-card-body">
              <div className="timesheet">
                <Row>
                  <Col xl={leftCol} lg={leftCol} md={leftCol} sm={leftCol} xs={leftCol}>
                    <label className="text-primary label-text-template mr-2">TEMPLATE</label>
                    {/* <span className="w-100">
                    <DatePicker
                      onChange={(date, dateString) => {
                        console.log(date, dateString);
                      }}
                      picker="week"
                      className="mr-2"
                      format={"YYYY-MM-DD"}
                      style={{ verticalAlign: "super" }}
                    />
                  </span>
                   */}

                    <span className="pull-right">
                      <button
                        className="btn btn-primary app-button"
                        onClick={(e) => {
                          this.showModalScanSignalHandler(true);
                        }}
                      >
                        QR Scan
                      </button>
                      <button
                        className="btn btn-primary app-button"
                        onClick={(e) => {
                          this.clickAddNewTemplate();
                        }}
                      >
                        New Line
                      </button>
                      <button
                        className="btn btn-primary app-button"
                        onClick={(e) => {
                          // this.props.router.history.push('/template');
                          this.props.history.push('main');
                        }}
                      >
                        Back to Timesheet
                      </button>
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col className="timesheet-left" style={{ transition: 'all 0.3s ease', position: 'relative' }} xl={leftCol} lg={leftCol} md={leftCol} sm={leftCol} xs={leftCol}></Col>
                  <Col className="timesheet-right" style={{ transition: 'all 0.3s ease', transitionDelay: '0.2s' }} xl={rightCol} lg={rightCol} md={rightCol} sm={rightCol} xs={rightCol}></Col>
                </Row>
                <Row style={{ overflowX: 'hidden' }}>
                  <Col style={{ transition: 'all 0.3s ease', position: 'relative' }} xl={leftCol} lg={leftCol} md={leftCol} sm={leftCol} xs={leftCol}>
                    {/* <i
                    className={` icon-arrow-expand icon icon-arrow-${isExpandRight ? "left" : "right"} pointer`}
                    onClick={(e) => {
                      this.toggeExpanRight();
                    }}
                  ></i> */}
                    <div className="table-responsive">
                      <table className={`table table-template-left ${isEditMode ? ' open-edit-mode ' : ''}`}>
                        <thead className=" ">
                          <tr>
                            {TABLE_LIST_COLUMN_TEMPLATE.map((aColumn, idx) => {
                              return (
                                this.isShowColumn(aColumn.key) && (
                                  <th key={idx} className={`${idx == 0 ? 'first' : ''}`}>
                                    <span>{aColumn.label}</span>
                                  </th>
                                )
                              );
                            })}

                            {/* 
                            {this.isShowColumn(COLUMNS_KEY_TASK.WbsElement) && (
                              <th>
                                <span>{COLUMNS_KEY_LABEL_TASK[COLUMNS_KEY_TASK.WbsElement]}</span>
                              </th>
                            )} */}

                            <th className="last">
                              {/* <i
                              className="fa fa-ellipsis-v text-primary"
                              onClick={(e) => {
                                this.showSelectColumnTa skTable();
                              }}
                            ></i> */}
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {listTemplate.map((template, idx) => {
                            const isEditOnRow = action == ACTION_UPDATE && currentTemplate && template.id == currentTemplate.id && !template.isNew;
                            const isAddOnRow = action == ACTION_CREATE && currentTemplate && template.id == currentTemplate.id && template.isNew;
                            const isShowInputForm = currentTemplate && (isEditOnRow || isAddOnRow);
                            return (
                              <tr
                                key={idx}
                                style={{ position: 'relative' }}
                                className={`${idx == 0 ? ' first-row ' : ''}  ${idx == listTemplate.length - 1 ? ' last-row ' : ''} 
                            ${isEditOnRow ? ' current-template ' : isAddOnRow ? ' on-new-template ' : ''}
                            ${template.isError ? ' error ' : ''}
                            `}
                              >
                                {TABLE_LIST_COLUMN_TEMPLATE.map((aColumn, idxCol) => {
                                  return (
                                    this.isShowColumn(aColumn.key) && (
                                      <td key={idxCol}>
                                        {/* {idxCol == 0 && template.isError && this.tooltipTaskError(template)} */}
                                        {isShowInputForm ? (
                                          <input
                                            className="form-control input-allow-change-value"
                                            disabled={aColumn.isDisableInput}
                                            onChange={(e) => {
                                              this.onChangeInputCurrentTemplate(e, currentTemplate, idx, aColumn.key);
                                            }}
                                            value={currentTemplate[aColumn.key]}
                                            // onKeyDown={(e) =>{
                                            //   console.log(`keydown of input`);
                                            //   this.onHandleKeydownOfRow(e, {
                                            //     enterKey: () => {
                                            //       this.submitSaveEditOnOneRow();
                                            //     },
                                            //     escKey: () => {
                                            //       this.closeEditOnOneRow(idx);
                                            //     },
                                            //   })
                                            // }}
                                          />
                                        ) : (
                                          <span>{template[aColumn.key]}</span>
                                        )}
                                      </td>
                                    )
                                  );
                                })}

                                {/* 
                                {this.isShowColumn(COLUMNS_KEY_TASK.Act) && (
                                  <td>
                                    {isShowInputForm ? (
                                      <input
                                        className="form-control input-allow-change-value"
                                        onChange={(e) => {
                                          this.onChangeInputCurrentTemplate(e, currentTemplate, idx, COLUMNS_KEY_TASK.Act);
                                        }}
                                        value={currentTemplate[COLUMNS_KEY_TASK.Act]}
                                      />
                                    ) : (
                                      <span>{template[COLUMNS_KEY_TASK.Act]}</span>
                                    )}
                                  </td>
                                )}
                               */}
                                <td>
                                  {isEditOnRow || isAddOnRow ? (
                                    <span className="template-action">
                                      <i
                                        className="fa fa-pencil pointer text-primary mr-2"
                                        onClick={(e) => {
                                          this.submitSaveEditOnOneRow();
                                        }}
                                      ></i>
                                      <i
                                        className="fa fa-close pointer"
                                        onClick={(e) => {
                                          this.closeEditOnOneRow(template, idx);
                                        }}
                                      ></i>
                                    </span>
                                  ) : (
                                    <span className="template-action">
                                      <Dropdown
                                        className="dotdotdot"
                                        trigger={'hover'}
                                        overlay={
                                          <Menu>
                                            <Menu.Item key={1}>
                                              <span
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.onActionUpsertTemplate(ACTION_UPDATE, template, idx);
                                                }}
                                              >
                                                Edit
                                              </span>
                                            </Menu.Item>
                                            <Menu.Item key={2}>
                                              <span
                                                className="text-danger w-100"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.submitDeleteTemplate(template, idx);
                                                }}
                                              >
                                                Delete
                                              </span>
                                            </Menu.Item>
                                          </Menu>
                                        }
                                      >
                                        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                                          <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                        </a>
                                      </Dropdown>
                                    </span>
                                  )}
                                </td>
                                {/* <span style={{position:'absolute', right:0, top:0}}>
                              <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                              </span> */}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            {this.state.showModalScanSignal && (
              <Modal
                title={<span className="text font-20">Scanner</span>}
                // centered
                open={true}
                onOk={() => {
                  // this.closeAllModal();
                }}
                onCancel={() => {
                  this.closeAllModal();
                }}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
                width={'450px'}
              >
                <div>
                  <ScannerComponent
                    closeModal={async (output = null) => {
                      console.log(`[template page] close scanner modal `, output);
                      await this.setStateAsync({
                        showModalScanSignal: false,
                      });
                      if (output && output.task) {
                        await this.scanCompleteGotNewTemplate(output.task);
                      }
                    }}
                  />
                </div>
              </Modal>
            )}
          </div>
        </BlockUi>
      </Auxiliary>
    );
  }
}
