/* eslint-disable */
import {
  HIDE_MESSAGE,
  INIT_URL,
  SHOW_MESSAGE,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER_SUCCESS,
} from 'constants/ActionTypes';
import { FETCH_LIST_TASKS_ERROR, FETCH_LIST_TASKS_START, FETCH_LIST_TASKS_SUCCESS, SIGNIN_USER_ERROR, SIGNOUT_USER } from '../../constants/ActionTypes';
import { clearLocalStorage, getUserAuthoried, setUserAuthoried } from '../../util/helper';
import { v4 } from 'uuid';

const INIT_TASK_STATE = {
  loading: false,
  tasks: [],
  error: null,
};

export default (state = INIT_TASK_STATE, action) => {
  const { tasks, error } = action.payload || {};

  switch (action.type) {
    case FETCH_LIST_TASKS_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_LIST_TASKS_SUCCESS: {
      let newTasks = [
        ..._.cloneDeep(tasks),
        //TEST
        // , ..._.cloneDeep(tasks), ..._.cloneDeep(tasks)
      ];
      return {
        ...state,
        tasks: newTasks.map((r) => {
          r.id = r.id || v4();
          return r;
        }),
        loading: false,
        error: null,
      };
    }
    case FETCH_LIST_TASKS_ERROR: {
      return {
        ...state,
        error: error,
        loading: false,
      };
    }
    default:
      return state;
  }
};
