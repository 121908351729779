/* eslint-disable */
import React from 'react';
import { Col, Row, DatePicker, Space, Modal, Menu, Dropdown, Icon, message, Tooltip as AntdToolTip } from 'antd';
import ReactJson from 'react-json-view';

import { Area, AreaChart, Line, LineChart, ResponsiveContainer, Tooltip } from 'recharts';
import ChartCard from 'components/dashboard/Crypto/ChartCard';
import Auxiliary from 'util/Auxiliary';
import Portfolio from 'components/dashboard/Crypto/Portfolio';
import BalanceHistory from 'components/dashboard/Crypto/BalanceHistory';
import SendMoney from 'components/dashboard/Crypto/SendMoney';
import RewardCard from 'components/dashboard/Crypto/RewardCard';
import CurrencyCalculator from 'components/dashboard/Crypto/CurrencyCalculator';
import CryptoNews from 'components/dashboard/Crypto/CryptoNews';
import DownloadMobileApps from 'components/dashboard/Crypto/DownloadMobileApps';
import OrderHistory from 'components/dashboard/Crypto/OrderHistory';
import { increamentData, lineData } from '../routes/main/Metrics/data';
import * as _ from 'lodash';
import * as uuid from 'uuid';
import { createLogPromise, deleteLogPromise, listUsersPromise, updateLogPromise } from '../appRedux/actions/Log';
import BlockUi from 'react-block-ui';
import { COLUMNS_KEY_TASK, COLUMNS_KEY_DATE_OF_WEEK } from '../app.const';
import {
  formatDetailsErrorArrayFromApi,
  openNotification,
  parseJson,
  // removeFieldNotAllowUpdateLog,
  // removeFieldsNotAllowAddLog,
  // removeFieldsNotAllowCreate,
  // removeFieldsNotAllowUpdate,
} from '../util/helper';
import moment from 'moment';
import { listUserPromise } from '../appRedux/actions/User';
const { RangePicker } = DatePicker;
const ACTION_CREATE = 'ACTION_CREATE';
const ACTION_UPDATE = 'ACTION_UPDATE';
const ACTION_DELETE = 'ACTION_DELETE';
const KEYS_CHECK_PAYLOAD_BEFORE_SUBMIT = ['act', 'plant', 'recCCtr', 'recOrder', 'sendCostCenter'];
const LOG_COLUMNS = [
  // {
  //   "id": 0,
  //   "username": "string",
  //   "email": "string",
  //   "phone": "string",
  //   "address": "string",
  //   "displayName": "string",
  //   "dayforceToken": "string",
  //   "firstName": "string",
  //   "lastName": "string",
  //   "xRefCode": "string",
  //   "orgXRefCode": "string",
  //   "expiredTime": "2023-11-20T16:38:22.211Z"
  // }
  {
    key: 'id',
    type: 'text',
    // render: (columnData, {click})=>{
    //   if(!columnData) return '';
    //   return <>
    //     <span>{columnData}</span>
    //     <i className="fa fa-eye pointer ml-2 text-primary" onClick={e=> click && click()}></i>
    //   </>
    // }
  },
  {
    key: 'xRefCode',
    type: 'text',
  },
  {
    key: 'orgXRefCode',
    type: 'text',
  },
  {
    key: 'username',
    type: 'text',
  },
  {
    key: 'displayName',
    type: 'text',
  },
  {
    key: 'email',
    type: 'text',
  },
  {
    key: 'phone',
    type: 'text',
  },
  {
    key: 'address',
    type: 'text',
  },
  {
    key: 'firstName',
    type: 'text',
  },
  {
    key: 'lastName',
    type: 'text',
  },
  // {
  //   key: 'createdAt',
  //   type: 'text',
  //   mergeColumn: ['createdAt'],
  //   render: (columnData, opt=null)=>{
  //     if(!columnData) return '';
  //     return moment(columnData).format('HH:mm:ss DD/MM/YY')
  //   }
  // },
  // {
  //   key: 'statusCode',
  //   type: 'text',
  // },
  // {
  //   key: 'method',
  //   type: 'text',
  // },
  // {
  //   key: 'url',
  //   type: 'html',
  //   render: (columnData, {click})=>{
  //     if(!columnData) return '';
  //     let url = columnData;
  //     // console.log(columnData);
  //     let urlArr = String(url).split('?');
  //     let query = urlArr[1];
  //     let value = String(url).replace(/.*\.com/,'').toString();
  //     return <AntdToolTip title={url}>
  //       <span>{value}</span>
  //       {/* <span className="text-primary pointer" onClick={e=>{
  //         click && click();
  //       }}> more detail</span> */}
  //     </AntdToolTip>
  //   }
  // },
  // {
  //   key: 'error',
  //   type: 'text',
  //   className: 'log-error',
  //   render: (columnData, {click})=>{
  //     if(!columnData) return '';

  //     return <AntdToolTip title={columnData}>
  //       <span>{columnData}</span>
  //       {/* <i className="fa fa-eye pointer" onClick={e=> click && click()}></i> */}
  //     </AntdToolTip>
  //   }
  // },
  // {
  //   key: 'userId',
  //   type: 'text',
  // },
];
export default class UserPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpandRight: false,
      listUsers: [],

      blockUi: false,
      showRightBlock: false,
      columnDisplay: LOG_COLUMNS.map((k) => {
        return {
          columnKey: k.key,
          isShow: true,
        };
      }),
      tmpColumnDisplay: [],
      action: '',
      // fromDate: moment().add(-7,'days').format('YYYY-MM-DD'),
      // toDate: moment().format('YYYY-MM-DD'),
      showModalLogDetail: false,
      // currentLog: null,
      searchCondition: {
        displayName: '',
        xRefCode: '',
      },
    };
  }
  setStateAsync(stateUpdate) {
    return new Promise((resolve) => {
      this.setState(stateUpdate, () => resolve());
    });
  }
  startBlockUi = () => {
    this.setState({
      blockUi: true,
    });
  };
  stopBlockUi = () => {
    this.setState({
      blockUi: false,
    });
  };
  componentDidMount = async () => {
    this.startBlockUi();
    await this.getUsersData();
    this.stopBlockUi();
  };
  getUsersData = async () => {
    let { displayName, xRefCode } = this.state.searchCondition;
    try {
      let res = await listUserPromise({
        ...(displayName ? { displayName } : {}),
        ...(xRefCode ? { xRefCode } : {}),
      });
      console.log('get logs ', res);
      this.setState({
        listUsers: res,
      });
    } catch (error) {
      console.log(error);
      message.error(error.message || JSON.stringify(error));
    }
  };
  isShowColumn = (columnKey) => {
    let { columnDisplay } = this.state;
    let find = _.find(columnDisplay, { columnKey, isShow: true });
    return !!find;
  };
  isShowColumnDateOfWeek = (columnKey) => {
    return true;
  };
  // submitDeleteLog = async (log, idx) => {
  //   Modal.confirm({
  //     title: "Confirm !",
  //     content: "Do you want to delete this log ?",
  //     okText: "Yes",
  //     cancelText: "No",
  //     onOk: async () => {
  //       this.startBlockUi();
  //       try {
  //         let id = encodeURIComponent(log.id);
  //         let res = await deleteLogPromise(id);
  //         message.success("Delete log successfully");
  //       } catch (error) {
  //         console.log(error);
  //         message.error(error.message);
  //       }
  //       await this.getUsersData();
  //       this.stopBlockUi();
  //     },
  //     onCancel: () => {},
  //   });
  // };
  // onActionUpsertLog = (action, log, idx) => {
  //   if (action == ACTION_UPDATE) {
  //     this.setState({
  //       action: ACTION_UPDATE,
  //       currentLog: _.cloneDeep(log),
  //     });
  //   } else if (action == ACTION_CREATE) {
  //     this.setState({
  //       action: ACTION_CREATE,
  //       currentLog: _.cloneDeep(log),
  //     });
  //   }
  // };
  onChangeInputCurrentLog = async (e, aLog, idx, key) => {
    let currentLog = this.state.currentLog;
    currentLog[key] = e.target.value;
    this.setState({
      currentLog,
    });
  };
  closeAllModal = async () => {
    Modal.destroyAll();
    await this.setStateAsync({
      action: '',
      // showModalDisplayColumn: false,
      // showModalAddToLog: false,
      // showModalDeleteTask: false,
      // showModalScanSignal: false,
      // showModalUseLog: false,
      currentLog: null,
    });
  };

  showModalMoreDetail = (log) => {
    this.setState({
      showModalLogDetail: true,
      currentLog: { ...log },
    });
  };

  render() {
    let { isExpandRight, showRightBlock, listUsers, action, currentLog, showModalLogDetail, searchCondition } = this.state;
    let rightCol = !isExpandRight ? 0 : 8;
    let leftCol = !isExpandRight ? 24 : 16;
    const isEditMode = !!currentLog && [ACTION_CREATE, ACTION_UPDATE].includes(action);

    return (
      <Auxiliary>
        <BlockUi blocking={this.state.blockUi}>
          <div className="log-page  ant-card">
            <div className=" ant-card-body">
              <div className="timesheet">
                <Row>
                  <Col xl={leftCol} lg={leftCol} md={leftCol} sm={leftCol} xs={leftCol}>
                    <div className="row">
                      <div className="col-1">
                        <label className="text-primary label-text-log mr-2">User</label>
                      </div>
                      <div className="col-3">
                        <input
                          className="form-control app-form-control"
                          value={searchCondition.displayName}
                          onChange={(e) => {
                            searchCondition.displayName = e.target.value;
                            this.setState({
                              searchCondition,
                            });
                          }}
                          placeholder="Display name"
                        />
                      </div>
                      <div className="col-3">
                        <input
                          className="form-control app-form-control"
                          value={searchCondition.xRefCode}
                          onChange={(e) => {
                            searchCondition.xRefCode = e.target.value;
                            this.setState({
                              searchCondition,
                            });
                          }}
                          placeholder="xRefCode"
                        />
                      </div>
                      <div className="col-2">
                        <button
                          className="btn btn-primary btn-sm btn-small app-button"
                          onClick={async (e) => {
                            e.preventDefault();
                            this.startBlockUi();
                            await this.getUsersData();
                            this.stopBlockUi();
                          }}
                        >
                          Search
                        </button>
                      </div>
                      <div className="col-3 text-right">
                        <button
                          className="btn btn-primary app-button"
                          onClick={(e) => {
                            this.props.history.push('main');
                          }}
                        >
                          Manage Task
                        </button>
                      </div>
                      {/* <div className="col-3"></div> */}
                    </div>
                    {/* <label className="text-primary label-text-log mr-2">User</label> */}
                    {/* <RangePicker onChange={async(dates, dateStrings )=>{
                      console.log(dates, dateStrings);
                      if(Array.isArray(dateStrings) && dateStrings[0] && dateStrings[1]){
                        await this.setStateAsync({
                          fromDate: dateStrings[0],
                          toDate: dateStrings[1]
                        });
                        setTimeout(async()=>{
                          this.startBlockUi();
                          await this.getUsersData();
                          this.stopBlockUi();
                        },1000)
                      }
                    }} /> */}
                    {/* <DatePicker
                      onChange={(date, dateString) => {
                        console.log(date, dateString);
                      }}
                      // picker="week"
                      className="mr-2"
                      format={"YYYY-MM-DD"}
                      style={{ verticalAlign: "super" }}
                    /> */}

                    <span className="pull-right"></span>
                  </Col>
                </Row>
                <Row>
                  <Col className="timesheet-left" style={{ transition: 'all 0.3s ease', position: 'relative' }} xl={leftCol} lg={leftCol} md={leftCol} sm={leftCol} xs={leftCol}></Col>
                  <Col className="timesheet-right" style={{ transition: 'all 0.3s ease', transitionDelay: '0.2s' }} xl={rightCol} lg={rightCol} md={rightCol} sm={rightCol} xs={rightCol}></Col>
                </Row>
                <Row style={{ overflowX: 'hidden' }}>
                  <Col style={{ transition: 'all 0.3s ease', position: 'relative' }} xl={leftCol} lg={leftCol} md={leftCol} sm={leftCol} xs={leftCol}>
                    {/* <i
                    className={` icon-arrow-expand icon icon-arrow-${isExpandRight ? "left" : "right"} pointer`}
                    onClick={(e) => {
                      this.toggeExpanRight();
                    }}
                  ></i> */}
                    <div className="table-responsive">
                      <table className={`table table-striped table-hover table-log-left ${isEditMode ? ' open-edit-mode ' : ''}`}>
                        <thead className="bg-info">
                          <tr>
                            {LOG_COLUMNS.map((column, i) => {
                              return (
                                this.isShowColumn(column.key) && (
                                  <th key={i} style={column.style ? column.style : {}} className={column.className || ''}>
                                    <span style={column.style ? column.style : {}}>{column.key}</span>
                                  </th>
                                )
                              );
                            })}
                            {/* <th className="last">
                            </th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {listUsers.map((log, idx) => {
                            const isEditOnRow = action == ACTION_UPDATE && currentLog && log.id == currentLog.id && !log.isNew;
                            const isAddOnRow = action == ACTION_CREATE && currentLog && log.id == currentLog.id && log.isNew;
                            const isShowInputForm = currentLog && (isEditOnRow || isAddOnRow);
                            return (
                              <tr
                                key={idx}
                                style={{ position: 'relative' }}
                                className={`${idx == 0 ? ' first-row ' : ''}  ${idx == listUsers.length - 1 ? ' last-row ' : ''} 
                            ${isEditOnRow ? ' current-log ' : isAddOnRow ? ' on-new-log ' : ''}
                            ${log.isError ? ' error ' : ''}
                            `}
                              >
                                {LOG_COLUMNS.map((column, i) => {
                                  return (
                                    this.isShowColumn(column.key) && (
                                      <td key={i} style={column.style ? column.style : {}} className={column.className || ''}>
                                        {column.type == 'json' || column.type == 'html' || column.render ? (
                                          column.render(log[column.key], {
                                            click: () => {
                                              this.showModalMoreDetail(log);
                                            },
                                          })
                                        ) : column.mergeColumn ? (
                                          <span style={column.style ? column.style : {}}>
                                            {column.mergeColumn.map((colMerge, iMerge) => {
                                              return (
                                                <span className="mr-2 d-block" key={iMerge}>
                                                  {log[colMerge]}
                                                </span>
                                              );
                                            })}
                                          </span>
                                        ) : (
                                          <span>{log[column.key]}</span>
                                        )}
                                      </td>
                                    )
                                  );
                                })}
                                {/* <td>
                                  {isEditOnRow || isAddOnRow ? (
                                    <span className="log-action">
                                      <i
                                        className="fa fa-pencil pointer text-primary mr-2"
                                        onClick={(e) => {
                                          this.submitSaveEditOnOneRow();
                                        }}
                                      ></i>
                                      <i
                                        className="fa fa-close pointer"
                                        onClick={(e) => {
                                          this.closeEditOnOneRow(log, idx);
                                        }}
                                      ></i>
                                    </span>
                                  ) : (
                                    <span className="log-action">
                                      <Dropdown
                                        className="dotdotdot"
                                        trigger={"hover"}
                                        overlay={
                                          <Menu>
                                            <Menu.Item key={1}>
                                              <span
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.onActionUpsertLog(ACTION_UPDATE, log, idx);
                                                }}
                                              >
                                                Edit
                                              </span>
                                            </Menu.Item>
                                            <Menu.Item key={2}>
                                              <span
                                                className="text-danger w-100"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.submitDeleteLog(log, idx);
                                                }}
                                              >
                                                Delete
                                              </span>
                                            </Menu.Item>
                                          </Menu>
                                        }
                                      >
                                        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                                          <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                        </a>
                                      </Dropdown>
                                    </span>
                                  )}
                                </td> */}
                                {/* <span style={{position:'absolute', right:0, top:0}}>
                              <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                              </span> */}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          {/* <Modal
            title={<span className="text font-20">Log</span>}
            // centered
            open={showModalLogDetail}
            onOk={() => {
              // this.closeAllModal();
              // this.submitUseTemplateAndConvertToTask();
              this.setState({showModalLogDetail: false})
            }}
            onCancel={() => {
              this.setState({showModalLogDetail: false})
            }}
            // cancelButtonProps={{ style: { display: "none" } }}
            width={800}
          >
            {currentLog &&
              Object.keys(currentLog || {}).map((k,i)=>{
                return <div key={i} className="row mb-2">
                <div className="col-2 text-left">
                  {k}
                </div>
                <div className="col-10 text-left">
                  {(k=='payload' || k=='response') && currentLog[k]
                  ? <ReactJson src={parseJson(currentLog[k])} displayDataTypes={false} name={false} /> : currentLog[k]}
                </div>
              </div>
              })
            }
          </Modal> */}
        </BlockUi>
      </Auxiliary>
    );
  }
}
