/* eslint-disable */
import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_FACEBOOK_USER,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_GITHUB_USER,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_TWITTER_USER,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
} from 'constants/ActionTypes';
import { FETCH_LIST_TASKS_ERROR, FETCH_LIST_TASKS_START, FETCH_LIST_TASKS_SUCCESS, SIGNIN_USER_ERROR } from '../../constants/ActionTypes';
import { TOKEN_FAKE, USER_FAKE } from '../../sampleData';
import axios from '../../util/Api';
import { API_URL } from '../../app.const';
import { loginDataSample } from '../../sampleData/loginDataSample';
import _ from 'lodash';
// export const listTemplatesReduxAction = (params = {}) => {
//   const cb = params.cb;
//   return async (dispatch, getState) => {
//     // console.log(`reduxthunk: signInWithEmailPassword {username, password, cb} = `, { username, password, cb, oldState: getState() });
//     dispatch({
//       type: FETCH_LIST_TASKS_START,
//     });
//     let url = API_URL + "/templates";
//     try {
//       let result = await axios.get(url, {
//         params: _.pick(params, ["fromDate", "toDate", "userId", "statusCode", "url", "method", "limit", "offset"]),
//       });
//       console.log("[listTemplatesAction success] result", result.data);
//       if (!result || !result.data) {
//         // throw new Error(`Call api ${url} fail with empty data`);
//         if (cb) cb(new Error(`Call api ${url} fail with empty data`), null);
//       }
//       if (cb) cb(null, result.data);
//       return dispatch({
//         type: FETCH_LIST_TASKS_SUCCESS,
//         payload: { templates: result.data, error: null },
//       });
//     } catch (error) {
//       console.error("[listTemplatesAction] error ", error);
//       if (cb) cb(error, null);
//       return dispatch({
//         type: FETCH_LIST_TASKS_ERROR,
//         payload: { error },
//       });
//     }
//   };
// };
export const listTemplatePromise = async (params = {}) => {
  let url = API_URL + '/templates';
  try {
    let result = await axios.get(url, {
      params: _.pick(params, ['fromDate', 'toDate', 'userId', 'statusCode', 'url', 'method', 'limit', 'offset']),
    });
    console.log('[listTemplatesPromise success] result', result);
    if (!result || !result.data) {
      return [];
    }
    return result.data.map((r) => {
      r.id = r.id || v4();
      return r;
    });
  } catch (error) {
    console.error('[listTemplatesPromise] error ', error);
    throw error;
  }
};
export const updateTemplatePromise = async (id, params) => {
  try {
    let result = await axios.put(API_URL + `/templates/${id}`, {
      ...params,
    });
    console.log('[updateTemplatePromise success] result', result.data);
    return result.data;
  } catch (error) {
    console.log('[updateTemplatePromise error]', error);
    throw error;
  }
};

export const createTemplatePromise = async (params) => {
  try {
    let result = await axios.post(API_URL + `/templates/bulk-create`, {
      ...params,
    });
    console.log('[createTemplatePromise success] result', result.data);
    return result.data;
  } catch (error) {
    console.log('[createTemplatePromise error]', error);
    throw error;
  }
};

export const deleteTemplatePromise = async (id) => {
  try {
    let result = await axios.delete(API_URL + `/templates/${id}`, {});
    console.log('[deleteTemplatePromise success] result', result.data);
    return result.data;
  } catch (error) {
    console.log('[deleteTemplatePromise error]', error);
    throw error;
  }
};
