/* eslint-disable */
import { Col, Row, DatePicker, Space, Modal, Menu, Dropdown, Icon, message, notification } from 'antd';
import { VERSION_APP } from '../app.const';
// import { VERSION_APP } from './config';

export const cachingClear = async () => {
  let version = localStorage.getItem('VERSION_APP');
  if (version == VERSION_APP) {
    //up to date
    console.log(`[Welldone] App is up-to-date version ${VERSION_APP}`);
  }
  if (!version || version != VERSION_APP) {
    console.log(`[Opp!] App is need to update/remove cache`, { version, upToVersion: VERSION_APP });
    if ('caches' in window) {
      try {
        let keyss = await caches.keys();
        keyss = keyss || [];
        for (let key of keyss) {
          console.log(`[success] Cleaned cache for ${key}`);
          await caches.delete(key);
        }
      } catch (error) {
        console.error(error);
      }
      // Makes sure the page reloads. Changes are only visible after you refresh.
      window.location.reload(true);
    }
    localStorage.clear();
    localStorage.setItem('VERSION_APP', VERSION_APP);
  }
};

export const clearCacheWithVersionJsonApi = async () => {
  let currentVersion = VERSION_APP;
  try {
    let result = await fetch('/version.json', {
      headers: {
        ['Content-Type']: 'application/json',
      },
    });
    let data = await result.json();
    if (!data || !data.version) return;
    let newVersion = data.version;
    let isNeedUpdate = currentVersion !== newVersion;
    // console.log(`isNeedUpdate `, isNeedUpdate);
    console.log(`[Check newVersion] current version ${currentVersion}, newVersion ${newVersion}`, { isNeedUpdate });
    if (isNeedUpdate) {
      console.log(`[Opp!] New version available ${newVersion}, need to update`, { currentVersion, newVersion });
      Modal.confirm({
        title: `${newVersion} is available!`,
        content: `Current version is ${currentVersion}. You need to update to new version ${newVersion}`,
        onOk: async () => {
          if ('caches' in window) {
            try {
              let keyss = await caches.keys();
              keyss = keyss || [];
              for (let key of keyss) {
                console.log(`[success] Cleaned cache for ${key}`);
                await caches.delete(key);
              }
            } catch (error) {
              console.error(error);
            }
            window.location.reload(true);
          }
        },
        cancelButtonProps: { style: { display: 'none' } },
      });
    }
  } catch (error) {
    console.error(error);
  }
};
