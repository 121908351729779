/* eslint-disable */
import React from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Avatar, Popover } from 'antd';
import { userSignOut } from 'appRedux/actions/Auth';
// import {connect, useDispatch, useSelector} from "react-redux";
import { userSignOutThunkAction } from '../../appRedux/actions/Auth';
import { getDisplayUsername } from '../../util/helper';

const UserProfile = (props) => {
  const dispatch = useDispatch();
  const { authUser } = useSelector((state) => state.auth);
  const userMenuOptions = (
    <ul className="gx-user-popover">
      {/* <li>My Account</li> */}
      {/* <li>Connections</li> */}
      {/* <li onClick={() => dispatch(userSignOut())}>Logout</li> */}
      <li onClick={() => props.signOutThunkAction()}>Logout</li>
    </ul>
  );
  if (!authUser) return null;

  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
        <Avatar src={'https://via.placeholder.com/150'} className="gx-size-40 gx-pointer gx-mr-3" alt="" />
        <span className="gx-avatar-name">
          {getDisplayUsername(authUser)}
          <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
        </span>
      </Popover>
    </div>
  );
};

function mapStateToProps(state) {
  return state;
}
// export default UserProfile;
export default connect(mapStateToProps, {
  signOutThunkAction: userSignOutThunkAction,
})(UserProfile);
