/* eslint-disable */
import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import URLSearchParams from 'url-search-params';
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';

import AppLocale from 'lngProvider';
import MainApp from './MainApp';
import SignIn from '../SignIn';
import SignUp from '../SignUp';
import { setInitUrl } from 'appRedux/actions/Auth';
import { onLayoutTypeChange, onNavStyleChange, setThemeType } from 'appRedux/actions/Setting';

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
} from '../../constants/ThemeSetting';
import { getLoginType, getTokenAuthoried, getUserAuthoried } from '../../util/helper';
import { SIGNIN_SSO_AZURE_SUCCESS, SIGNIN_USER_SUCCESS } from '../../constants/ActionTypes';
import { ACCESS_TOKEN_KEY, ID_TOKEN_KEY, LOGIN_TYPE_AZURE, LOGIN_TYPE_PASSWORD, TOKEN_KEY } from '../../app.const';

const RestrictedRoute = ({ component: Component, location, authUser, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/signin',
            state: { from: location },
          }}
        />
      )
    }
  />
);

const ContainerApp = (props) => {
  const dispatch = useDispatch();
  const { locale, navStyle, layoutType } = useSelector(({ settings }) => settings);
  const { authUser, initURL } = useSelector(({ auth }) => auth);

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  if (!authUser) {
    //check localStorage and set store user & token
    if (getUserAuthoried() && getTokenAuthoried()) {
      let loginType = getLoginType();
      if (loginType == LOGIN_TYPE_PASSWORD) {
        dispatch({
          type: SIGNIN_USER_SUCCESS,
          payload: {
            token: getTokenAuthoried(TOKEN_KEY),
            user: getUserAuthoried(),
            loginType: LOGIN_TYPE_PASSWORD,
          },
        });
      } else {
        //azure
        dispatch({
          type: SIGNIN_SSO_AZURE_SUCCESS,
          payload: {
            token: getTokenAuthoried(TOKEN_KEY),
            user: getUserAuthoried(),
            idToken: getTokenAuthoried(ID_TOKEN_KEY),
            accessToken: getTokenAuthoried(ACCESS_TOKEN_KEY),
            loginType: LOGIN_TYPE_AZURE,
          },
        });
      }
    }
  }

  useEffect(() => {
    if (initURL === '') {
      dispatch(setInitUrl(location.pathname));
    }
    const params = new URLSearchParams(location.search);

    if (params.has('theme')) {
      dispatch(setThemeType(params.get('theme')));
    }
    if (params.has('nav-style')) {
      dispatch(onNavStyleChange(params.get('nav-style')));
    }
    if (params.has('layout-type')) {
      dispatch(onLayoutTypeChange(params.get('layout-type')));
    }
    setLayoutType(layoutType);
    setNavStyle(navStyle);
    return function () {
      /**
       * Add cleanup code here
       */
    };
  }, []);

  const setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('full-layout');
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove('full-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('boxed-layout');
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('full-layout');
      document.body.classList.add('framed-layout');
    }
  };

  const setNavStyle = (navStyle) => {
    if (
      navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER
    ) {
      document.body.classList.add('full-scroll');
      document.body.classList.add('horizontal-layout');
    } else {
      document.body.classList.remove('full-scroll');
      document.body.classList.remove('horizontal-layout');
    }
  };

  useEffect(() => {
    // console.log('Container app: authUser ', authUser);
    // console.log('location.pathname', location.pathname);
    // console.log('initURL', initURL);

    if ((location.pathname != '/signin' || ['/', '', '/main'].includes(location.pathname)) && !authUser) {
      console.log('******* user not login, redirect sigin');
      history.push('/signin');
      return;
    }
    // if (location.pathname === '/' || location.pathname === '' || location.pathname === '/main') {
    //   if (!authUser) {
    //     console.log('******* user not login, redirect sigin');
    //     history.push('/signin');
    //     return;
    //   }
    // }
    if (location.pathname === '/' || location.pathname === '') {
      // if (!authUser) {
      //   console.log('******* user not login, redirect sigin');
      //   history.push('/signin');
      //   return;
      // }

      if (['', '/', '/signin'].includes(initURL)) {
        console.log('******* user logged, initURL empty, redirect home page', { initURL, authUser });
        let isAdmin = authUser && authUser.user && authUser.user.username == 'admin';
        //   history.push('/user');
        //   return;
        history.push(isAdmin ? '/user' : '/main');
        return;
      } else {
        console.log('******* user logged, redirect to ', { initURL, authUser });
        history.push(initURL);
        return;
      }
    }
    return function () {
      /**
       * Add cleanup code here
       */
    };
  }, [authUser, initURL, location, history]);

  const currentAppLocale = AppLocale[locale.locale];

  // console.log(`authUser`, authUser);
  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
        <Switch>
          <Route exact path="/signin" component={SignIn} />
          <Route exact path="/login" component={SignIn} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/register" component={SignUp} />
          <RestrictedRoute path={`${match.url}`} authUser={authUser} location={location} component={MainApp} />
        </Switch>
      </IntlProvider>
    </ConfigProvider>
  );
};

export default ContainerApp;
