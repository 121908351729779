/* eslint-disable */
import { ACCESS_TOKEN_KEY, COLUMNS_KEY_LABEL_TASK, COLUMNS_KEY_TASK, ID_TOKEN_KEY, IS_OPEN_SCANNER_POPUP_KEY, LOGIN_TYPE_KEY, TOKEN_KEY, USER_KEY } from '../app.const';
import * as moment from 'moment';
import { Col, Row, DatePicker, Space, Modal, Menu, Dropdown, Icon, message, notification } from 'antd';
// import { USER_KEY } from '../app.const';
const KEYS_NOT_ALLOW_UPSERT_TASK = ['userId', 'createdAt', 'updatedAt'];

export const setLoginType = (loginType = null) => {
  if (loginType === null) {
    localStorage.removeItem(LOGIN_TYPE_KEY);
  } else {
    localStorage.setItem(LOGIN_TYPE_KEY, loginType);
  }
};
export const getLoginType = () => {
  return localStorage.getItem(LOGIN_TYPE_KEY);
};

export const getUserAuthoried = () => {
  try {
    if (localStorage.getItem(USER_KEY)) {
      return JSON.parse(localStorage.getItem(USER_KEY));
    }
    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getTokenAuthoried = (key = null) => {
  if (!key) {
    return localStorage.getItem(TOKEN_KEY);
  }
  if (key) {
    return localStorage.getItem(key);
  }
  // try {
  //   if (localStorage.getItem(TOKEN_KEY)) {
  //     return localStorage.getItem(TOKEN_KEY);
  //   }
  // } catch (error) {
  //   console.error(error);
  // }
  return null;
};

export const setUserAuthoried = ({ token, idToken, accessToken }, user) => {
  if (idToken) {
    localStorage.setItem(ID_TOKEN_KEY, idToken);
  } else {
    localStorage.removeItem(ID_TOKEN_KEY);
  }

  if (accessToken) {
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
  } else {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
  }

  if (!token) {
    localStorage.removeItem(TOKEN_KEY);
  } else {
    localStorage.setItem(TOKEN_KEY, token);
  }

  if (user && typeof user === 'object') {
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  } else if (user === null) {
    localStorage.removeItem(USER_KEY);
  }
};

export const clearLocalStorage = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(USER_KEY);
  localStorage.removeItem(ID_TOKEN_KEY);
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.clear();
};

export const getDisplayUsername = (authUser) => {
  let displayName = authUser && authUser.displayName;
  displayName = displayName || (authUser && authUser.username);
  displayName = displayName || (authUser && authUser.email);
  return displayName;
};

export const getFromDateToDateOfCurrentWeek = (getMomentObjet = false) => {
  let fromDate = ''; // friday
  let toDate = ''; //   thursday
  let weekdayNumber = moment().weekday(); //if today is thursday it will return 4
  // console.log('weekdayNumber = ', {weekdayNumber});
  if (weekdayNumber === 4) {
    fromDate = moment().add(-7 + 1, 'days');
    toDate = moment(); //thursday it will return 4
  } else if (weekdayNumber === 5) {
    fromDate = moment(); //friday it will return 5
    toDate = moment().add(+6, 'days');
  } else {
    let currThursday = moment().weekday(4);
    if (weekdayNumber < 4) {
      //fromDate is Friday previous
      //toDate is thursday currentWeek
      // console.log('case 3 weekdayNumber < 4', {currThursday});
      fromDate = currThursday.clone().add(-6, 'days');
      toDate = currThursday.clone().add(0, 'days');
    } else if (weekdayNumber > 5) {
      //fromDate is Friday currentweek
      //toDate is thursday nextweek
      // console.log('case 4 weekdayNumber > 5', {currThursday});
      fromDate = currThursday.clone().add(0, 'days');
      toDate = currThursday.clone().add(6, 'days');
    }
  }
  if (getMomentObjet == true) {
    return {
      fromDate,
      toDate,
    };
  }
  fromDate = fromDate.format('YYYY-MM-DD');
  toDate = toDate.format('YYYY-MM-DD');
  // console.log({fromDate, toDate});
  return {
    fromDate,
    toDate,
  };
};

export const timeout = async (time = 1) => {
  return await new Promise((res, rej) => {
    setTimeout(() => {
      res(null);
    }, time * 1000);
  });
};

export const isArrayEqual = (x, y) => _.isEmpty(_.xorWith(x, y, _.isEqual));
export const removeNullValue = (obj) => {
  let newObj = {};
  for (let k in obj) {
    if (obj[k] !== null) {
      newObj[k] = obj[k];
    }
  }
  return newObj;
};
export const removeUndefinedValue = (obj) => {
  let newObj = {};
  for (let k in obj) {
    if (obj[k] !== undefined) {
      newObj[k] = obj[k];
    }
  }
  return newObj;
};
export const removeFieldsNotAllowCreate = (param) => {
  let params = _.cloneDeep(param);
  // [
  //   "isNew",
  //   "id",
  //   "Act",
  //   "Plant",
  //   "RecCCtrl",
  //   "RecOrder",
  //   "SendCostCenter",
  //   "ActType",
  //   "Network",
  //   "WbsElement",
  //   "WorkCenter",
  //   "Hours",
  //   "EmployeeXRefCode",
  //   "EmployeePayAdjustXRefCode",
  //   "PayAdjustmentCodeXRefCode",
  //   "OrgUnitXRefCode",
  //   "employeeXRefCode",
  //   "orgUnitXRefCode",
  //   "payAdjustmentCodeXRefCode",
  //   "PayAdjustmentDate",
  //   "isError",
  //   "errors",
  //   "createdAt",
  //   "updatedAt",
  //   "userId",
  //   "isLoadFromTemplate",
  //   ...KEYS_NOT_ALLOW_UPSERT_TASK,
  // ].forEach((k) => {
  //   delete params[k];
  // });
  let newParams = {};
  Object.keys({
    // [COLUMNS_KEY_TASK.Act]: "", //not submit Act to BE
    actType: '',
    network: '',
    plant: null,
    recCCtr: null,
    recOrder: null,
    wbsElement: '',
    sendCostCenter: null,
    workCenter: '',
    hours: '',
    payAdjustmentDate: '',
    [COLUMNS_KEY_TASK.AAType]: '',
    [COLUMNS_KEY_TASK.TocsID]: '',
  }).forEach((k) => {
    newParams[k] = params[k];
  });
  return newParams;
};
export const removeFieldsNotAllowValidate = (param) => {
  let params = _.cloneDeep(param);
  [
    'employeePayAdjustXRefCode',
    // "isLoadFromTemplate", "id", "isSelected", "orgUnitXRefCode", "employeeXRefCode", "payAdjustmentCodeXRefCode", "isError", "errors"
    ...KEYS_NOT_ALLOW_UPSERT_TASK,
  ].forEach((k) => {
    delete params[k];
  });
  return params;
};
export const removeFieldsNotAllowUpdate = (param) => {
  let params = _.cloneDeep(param);
  [
    'isLoadFromTemplate',
    'id',
    'isSelected',
    'orgUnitXRefCode',
    'employeeXRefCode',
    'payAdjustmentCodeXRefCode',
    'isError',
    'errors',
    'createdAt',
    'updatedAt',
    'userId',
    ...KEYS_NOT_ALLOW_UPSERT_TASK,
  ].forEach((k) => {
    delete params[k];
  });
  delete params[COLUMNS_KEY_TASK.Act]; //not submit Act to BE]
  return params;
};
export const removeFieldsNotAllowAddTemplate = (param) => {
  let params = _.cloneDeep(param);
  [
    'employeePayAdjustXRefCode',
    'employeeXRefCode',
    'payAdjustmentCodeXRefCode',
    'orgUnitXRefCode',
    'payAdjustmentDate',
    'hours',
    'id',
    'Act',
    'ActType',
    'AAType',
    'TocsID',
    'Plant',
    'EmployeePayAdjustXRefCode',
    'EmployeeXRefCode',
    'OrgUnitXRefCode',
    'Hours',
    'Network',
    'PayAdjustmentCodeXRefCode',
    'PayAdjustmentDate',
    'RecCCtrl',
    'RecOrder',
    'SendCostCenter',
    'WbsElement',
    'WorkCenter',
    'isNew',
    'isLoadFromTemplate',
  ].forEach((k) => {
    delete params[k];
  });
  // delete params["id"];
  // delete params["isSelected"];
  return params;
};
export const removeFieldNotAllowUpdateTemplate = (param) => {
  let params = _.cloneDeep(param);
  ['createdAt', 'updatedAt', 'userId', 'id', 'AAType', 'TocsID'].forEach((k) => {
    delete params[k];
  });
  return params;
};

export const extendLabelColumnForErrorMessage = (message, key) => {
  let label = '';
  if (key) {
    label = COLUMNS_KEY_LABEL_TASK[key] || 'N/A';
    return String(message).replace(`'${key}'`, label);
  }
  for (let k in COLUMNS_KEY_LABEL_TASK) {
    let lab = COLUMNS_KEY_LABEL_TASK[k];
    let haveKeyInMessage = String(message).includes(`'${k}'`);
    if (haveKeyInMessage) {
      message = String(message).replace(`'${k}'`, lab);
      break;
    }
  }
  return message;
};
export const formatDetailsErrorArrayFromApi = (error) => {
  let finalArrErrors = [];

  let details = error.details || [];
  if (!error.details || details.length == 0) {
    // k co details => check co response k ?
    if (error.response && _.isPlainObject(error.response)) {
      let processResults = error.response.processResults;
      console.log(`Error processResults`, processResults);
      if (Array.isArray(processResults)) {
        processResults.forEach((item, idx) => {
          finalArrErrors.push({
            key: '',
            message: item.message,
          });
        });
      } else if (_.isPlainObject(processResults)) {
        for (let k of processResults) {
          if (processResults[k].message) {
            finalArrErrors.push({
              key: k,
              message: processResults[k].message,
            });
          }
        }
      }
    } else if (error.message) {
      finalArrErrors.push({
        key: 'apiError',
        message: error.message,
      });
    }
  } else {
    for (let k in details) {
      let detail = details[k];
      if (String(detail.message).includes('payAdjustmentDate')) {
        continue;
      }
      // console.log(k);
      finalArrErrors.push({
        keyObject: String(k).replace('body.', ''),
        key: detail.key || String(k).replace('body.', ''),
        message: extendLabelColumnForErrorMessage(detail.message),
      });
    }
  }
  return finalArrErrors;
};

export const openNotification = (param) => {
  let { message, title, description, type } = param;
  type = type || 'success';
  notification[type]({
    message: title || message || 'Notification Title',
    description: description || 'This is the content of the notification.',
  });
};

export const parseJson = (data) => {
  // if(_.isNumber(data)) return {data};
  if (!data) return {};
  if (data && _.isString(data)) {
    return JSON.parse(data);
  }
  return data || {};
};
export const isLocalhost = () => {
  return window.location.hostname.includes('localhost');
};
export const errTotalHourLabel = (date) => {
  return {
    details: [
      {
        key: '',
        message: `Total hour on ${date} is over 24h`,
      },
    ],
  };
};
