/* eslint-disable */
import React from 'react';
import { Col, Row, DatePicker, Space, Modal, Menu, Dropdown, Icon, message, Tooltip as AntdToolTip, Table, Descriptions, Divider } from 'antd';

import Auxiliary from 'util/Auxiliary';
import * as _ from 'lodash';
import * as uuid from 'uuid';
import BlockUi from 'react-block-ui';
import moment from 'moment';
import { COLUMNS_KEY_LABEL_TASK, COLUMNS_KEY_TASK } from '../../app.const';
import { timeout } from '../../util/helper';
import parser from 'ua-parser-js';
const TEST_SCAN_CODE = '3003TTabTFMTabTab16SK6/30003501TabTabTabTabWORKTabTab';
const TEST_SCAN_CODE_Charlie = '3003TTabTFATabTabTab5001637TabTab0010TabWORKTab000000';
const TEST_SCAN_CODE2 = '3003T~ITFA~I~I~I5001637~I~I0010~IWORK~I00000~I~I~M'; //~I = Tab ~M = Enter
const TEST_FAKE_TASK = {
  sendCostCenter: '3003T',
  actType: 'TFM',
  recCCtr: '',
  wbsElement: '16SK6/30003501',
  recOrder: '',
  network: '',
  act: '',
  aaType: 'WORK',
  tocsId: '',
};
const ARRAY_TASK_COLUMN_MAP = [
  COLUMNS_KEY_TASK.SendCostCenter,
  COLUMNS_KEY_TASK.ActType,
  COLUMNS_KEY_TASK.RecCCtrl,
  COLUMNS_KEY_TASK.WbsElement,
  COLUMNS_KEY_TASK.RecOrder,
  COLUMNS_KEY_TASK.Network,
  COLUMNS_KEY_TASK.Act,
  COLUMNS_KEY_TASK.AAType,
  COLUMNS_KEY_TASK.TocsID,
];
export default class ScannerComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      browserInfo: {},
      idTimerTimeout: null,
      scanCompleteMessage: '',
      errorMessage: '',
      scanCode: '',
      finalScanCode: '',
      task: null,
    };
  }
  async componentDidMount() {
    // console.log(`parser`, parser);
    this.getBrowserInfo();
    console.log('[scanner componentDidMount] init listener event keydown ');
    let self = this;
    document.addEventListener('keydown', self.onkeydownHandler);
    //TEST
    // setTimeout(()=>{
    //   this.validateScanCodeFormat();
    // }, 1000)
  }
  componentWillUnmount() {
    console.log('[scanner componentWillUnmount] remove listener event keydown ');
    let self = this;
    const { idTimerTimeout } = self.state;
    document.removeEventListener('keydown', self.onkeydownHandler);
    if (idTimerTimeout) clearTimeout(idTimerTimeout);
  }
  closeScanner = ({ finalScanCode, task }) => {
    let self = this;
    // document.removeEventListener("keydown", self.onkeydownHandler);
    // console.log("[scanner] remove listener event keydown ");
    self.props.closeModal({
      finalScanCode,
      task,
    });
  };

  resetState = () => {
    this.setState({
      scanCode: '',
      finalScanCode: '',
      errorMessage: '',
      task: null,
      scanCompleteMessage: '',
    });
  };

  setStateAsync(stateUpdate) {
    return new Promise((resolve) => {
      this.setState(stateUpdate, () => resolve());
    });
  }
  onkeydownHandler = async (e) => {
    const isEnterKey = (e) => {
      return e.key === 'Enter' || e.keyCode === 13 || e.which === 13;
    };
    const self = this;
    const { idTimerTimeout, scanCode } = this.state;
    const key = String(e.key).toString();
    const keyCode = String(e.keyCode).toString();
    console.log(`[scanner] keydown key:${key} keyCode:${keyCode} ... [${scanCode}]`);

    if (idTimerTimeout) clearTimeout(idTimerTimeout);

    if (key) {
      if (key === 'F12') {
        e.preventDefault();
      }
      //last char from scanner machine is Enter
      if (isEnterKey(e) && scanCode) {
        console.log(`[scanner] Enter key:  key:${key} keyCode:${keyCode} scanCode=${scanCode}`);
        let finalScanCode = String(scanCode).toString();
        console.log('[Scanner] finalScanCode = ', finalScanCode);
        await this.setStateAsync({
          scanCode: '',
          finalScanCode: finalScanCode,
        });
        this.validateScanCodeFormat();
      } else {
        if (key !== 'Shift') {
          //&& code !== "ShiftLeft"
          let newScancode = `${scanCode}${key}`;
          await this.setStateAsync({
            scanCode: `${newScancode}`,
          });
          let hasEnterKey = /^.*\~M$/.test(newScancode);
          let hasTabKeyMatch = newScancode.includes('~I');
          //In somecase scanner machine not have last Enter key, only have ~M (2 keydown ~ and M)
          if ((key === 'M' || keyCode === '77') && hasTabKeyMatch && hasEnterKey) {
            console.log('[Scanner] last keydown M, check is correct scan code have ~I and ~M ? ', { hasEnterKey, hasTabKeyMatch });
            if (hasEnterKey && hasTabKeyMatch) {
              let finalScanCode = String(newScancode).toString().replace(/\~I/g, 'Tab').replace(/\~M/, '');
              // "3003T~ITFA~I~I~I5001637~I~I0010~IWORK~I00000~I~I~M" =>
              // "3003TTabTFATabTabTab5001637TabTab0010TabWORKTab00000TabTab"
              let validTask = this.formatScanCodeToTask(finalScanCode);
              console.log('[Scanner] last keydown M, finalScanCode = ', finalScanCode, ` validTask converted =`, validTask);
              if (validTask) {
                await this.setStateAsync({
                  scanCode: '',
                  finalScanCode: finalScanCode,
                });
                await this.validateScanCodeFormat(validTask);
              }
            }
          }
        }
      }
    }

    //auto clear scanCode after scanner complete
    let idTimerTimeout_ = setTimeout(async () => {
      //* Case 3: k co key Enter va k co ~M
      let finalScanCode = String(self.state.scanCode).toString();
      if (finalScanCode && finalScanCode.includes('Tab')) {
        let taskValid = self.formatScanCodeToTask(finalScanCode);
        console.log(`[scanner] timeout 0.5s check scanCode is correct format ? taskValid=`, taskValid);
        if (taskValid) {
          await this.setStateAsync({
            scanCode: '',
            finalScanCode: finalScanCode,
          });
          await this.validateScanCodeFormat(taskValid);
        }
      }

      // console.log(`[scanner] timeout trigger reset scanCode = '' `);
      await self.setStateAsync({
        scanCode: '',
      });
    }, 500);

    await self.setStateAsync({
      idTimerTimeout: idTimerTimeout_,
    });
  };
  formatScanCodeToTask = (finalScanCode) => {
    //split Tab
    let arrScanCode = String(finalScanCode).split('Tab');
    console.log(`[Scanner] array split Tab = `, arrScanCode);

    //check number of columns
    if (arrScanCode.length < ARRAY_TASK_COLUMN_MAP.length) {
      console.error(`[Scanner] arrScanCode not have enough length (${ARRAY_TASK_COLUMN_MAP.length}), but curr length= `, arrScanCode.length);
      return null;
    }
    let task = {};
    for (let i = 0; i < ARRAY_TASK_COLUMN_MAP.length; i++) {
      let k = ARRAY_TASK_COLUMN_MAP[i];
      task[k] = arrScanCode[i] || '';
    }
    return task;
  };

  validateScanCodeFormat = async (validTask = null) => {
    const { finalScanCode } = this.state;
    if (finalScanCode) {
      let task = validTask || this.formatScanCodeToTask(finalScanCode);
      if (!task) {
        message.error(`Data is invalid. Please try again.`);
        this.resetState();
        return;
      }
      console.log(`[Scanner] task got from scanData task= `, task);
      // message.success(`Scan completed, please review task in table below !`);
      await this.setStateAsync({
        task: task,
      });
      this.resetState();
      this.closeScanner({
        finalScanCode,
        task,
      });
    }
  };
  genDataTable = () => {
    let arr = [];
    if (this.state.task) {
      arr.push({ ...this.state.task, key: Date.now() });
    }
    return arr;
  };
  getBrowserInfo = () => {
    var userAgent = window.navigator.userAgent;
    var parserInfo = parser(userAgent);
    // console.log(`parserInfo`, parserInfo);
    this.setState({
      browserInfo: parserInfo,
    });
  };
  render() {
    let { scanCode, errorMessage, finalScanCode, task, scanCompleteMessage, browserInfo } = this.state;
    return (
      <Auxiliary>
        <div className="row">
          {/* <div className="col-12 text-center">
            <input className="form-control" placeholder="scan code" value={finalScanCode || ''} />
          </div> */}

          {errorMessage && (
            <div className="col-12 text-center">
              <i className="fa fa-warning text-danger mr-2"></i>
              <span className="text-danger">{errorMessage}</span>
            </div>
          )}

          {!finalScanCode && (
            <div className="col-12 text-center">
              <i className="fa fa-spin fa-spinner  text-primary mr-2"></i>
              <span className="text-primary">Waiting for signal from scanner</span>
            </div>
          )}
          {finalScanCode && this.genDataTable().length > 0 && (
            <>
              <div className="col-12 text-center" style={{ marginBottom: '15px' }}>
                <span>
                  <i className="fa fa-check text-success mr-2"></i>
                  Scan result: <span className="text-success">{finalScanCode}</span>
                </span>
              </div>

              <div className="col-12 text-center">
                <Table
                  pagination={false}
                  columns={[
                    {
                      title: COLUMNS_KEY_LABEL_TASK[COLUMNS_KEY_TASK.SendCostCenter],
                      key: COLUMNS_KEY_TASK.SendCostCenter,
                      dataIndex: COLUMNS_KEY_TASK.SendCostCenter,
                      render: (text) => <span>{text}</span>,
                    },
                    {
                      title: COLUMNS_KEY_LABEL_TASK[COLUMNS_KEY_TASK.ActType],
                      key: COLUMNS_KEY_TASK.ActType,
                      dataIndex: COLUMNS_KEY_TASK.ActType,
                      render: (text) => <span>{text}</span>,
                    },
                    {
                      title: COLUMNS_KEY_LABEL_TASK[COLUMNS_KEY_TASK.RecCCtrl],
                      key: COLUMNS_KEY_TASK.RecCCtrl,
                      dataIndex: COLUMNS_KEY_TASK.RecCCtrl,
                      render: (text) => <span>{text}</span>,
                    },
                    {
                      title: COLUMNS_KEY_LABEL_TASK[COLUMNS_KEY_TASK.WbsElement],
                      key: COLUMNS_KEY_TASK.WbsElement,
                      dataIndex: COLUMNS_KEY_TASK.WbsElement,
                      render: (text) => <span>{text}</span>,
                    },
                    {
                      title: COLUMNS_KEY_LABEL_TASK[COLUMNS_KEY_TASK.RecOrder],
                      key: COLUMNS_KEY_TASK.RecOrder,
                      dataIndex: COLUMNS_KEY_TASK.RecOrder,
                      render: (text) => <span>{text}</span>,
                    },
                    {
                      title: COLUMNS_KEY_LABEL_TASK[COLUMNS_KEY_TASK.Network],
                      key: COLUMNS_KEY_TASK.Network,
                      dataIndex: COLUMNS_KEY_TASK.Network,
                      render: (text) => <span>{text}</span>,
                    },
                    {
                      title: COLUMNS_KEY_LABEL_TASK[COLUMNS_KEY_TASK.Act],
                      key: COLUMNS_KEY_TASK.Act,
                      dataIndex: COLUMNS_KEY_TASK.Act,
                      render: (text) => <span>{text}</span>,
                    },
                    {
                      title: COLUMNS_KEY_LABEL_TASK[COLUMNS_KEY_TASK.AAType],
                      key: COLUMNS_KEY_TASK.AAType,
                      dataIndex: COLUMNS_KEY_TASK.AAType,
                      render: (text) => <span>{text}</span>,
                    },
                    {
                      title: COLUMNS_KEY_LABEL_TASK[COLUMNS_KEY_TASK.TocsID],
                      key: COLUMNS_KEY_TASK.TocsID,
                      dataIndex: COLUMNS_KEY_TASK.TocsID,
                      render: (text) => <span>{text}</span>,
                    },
                  ]}
                  dataSource={this.genDataTable()}
                />
                {scanCompleteMessage && (
                  <div style={{ marginTop: '15px' }}>
                    <span className="text-info">{scanCompleteMessage}</span>
                  </div>
                )}
              </div>
            </>
          )}
          {
            // browserInfo.browser && (
            //   <div className="col-12 text-left mt-5">
            //     <Divider>Browser Info</Divider>
            //     <Descriptions>
            //       <Descriptions.Item label="Browser">
            //         {browserInfo.browser.name}/ Version {browserInfo.browser.version}
            //       </Descriptions.Item>
            //       <Descriptions.Item label="Device">
            //         {browserInfo.device.model} / Vendor {browserInfo.device.vendor}
            //       </Descriptions.Item>
            //       <Descriptions.Item label="OS">
            //         {browserInfo.os.name} / Version {browserInfo.os.version}
            //       </Descriptions.Item>
            //       <Descriptions.Item label="Engine">
            //         {browserInfo.engine.name} / Version {browserInfo.engine.version}
            //       </Descriptions.Item>
            //       <Descriptions.Item label="User agent">{browserInfo.ua}</Descriptions.Item>
            //     </Descriptions>
            //   </div>
            // )
          }
        </div>
      </Auxiliary>
    );
  }
}
