/* eslint-disable */
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import 'assets/vendors/style';
import configureStore, { history } from './appRedux/store';
// import "./firebase/firebase";
import ContainerApp from './containers/App/index';
import { cachingClear, clearCacheWithVersionJsonApi } from './util/cacheClearHelper';
import { ssoAzureClientId, ssoAzureRedirectUrl, ssoAzureTenantId } from './app.const';

import 'react-block-ui/style.css';
import './../node_modules/antd/dist/antd.less';
import './styles/wieldy.less';

// MSAL configuration
//BrowserAuthOptions
const configuration = {
  auth: {
    clientId: ssoAzureClientId,
    appId: ssoAzureClientId,
    redirectUri: ssoAzureRedirectUrl,
    scopes: ['user.read'],
    authority: 'https://login.microsoftonline.com/' + ssoAzureTenantId,
  },
};
const publicClientApp = new PublicClientApplication(configuration);

const store = configureStore(/* provide initial state if any */);

const NextApp = () => {
  useEffect(() => {
    clearCacheWithVersionJsonApi();
    return function () {
      /**
       * Add cleanup code here
       */
    };
  }, []);
  return (
    <MsalProvider instance={publicClientApp} msalInstance={publicClientApp}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Switch>
            <Route path="/" component={ContainerApp} />
          </Switch>
        </ConnectedRouter>
      </Provider>
    </MsalProvider>
  );
};

export default NextApp;
