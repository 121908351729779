/* eslint-disable */
import React from 'react';
import { Col, Row, DatePicker, Space, Modal, Menu, Dropdown, Icon, message, notification, Tooltip as TooltipAntd, InputNumber } from 'antd';

import { Area, AreaChart, Line, LineChart, ResponsiveContainer, Tooltip } from 'recharts';
import ChartCard from 'components/dashboard/Crypto/ChartCard';
import Auxiliary from 'util/Auxiliary';
import Portfolio from 'components/dashboard/Crypto/Portfolio';
import BalanceHistory from 'components/dashboard/Crypto/BalanceHistory';
import SendMoney from 'components/dashboard/Crypto/SendMoney';
import RewardCard from 'components/dashboard/Crypto/RewardCard';
import CurrencyCalculator from 'components/dashboard/Crypto/CurrencyCalculator';
import CryptoNews from 'components/dashboard/Crypto/CryptoNews';
import DownloadMobileApps from 'components/dashboard/Crypto/DownloadMobileApps';
import OrderHistory from 'components/dashboard/Crypto/OrderHistory';
import { increamentData, lineData } from '../routes/main/Metrics/data';
import { taskSampleData } from '../sampleData/data';
import * as _ from 'lodash';
import * as uuid from 'uuid';
import BlockUi from 'react-block-ui';
import CircularProgress from 'components/CircularProgress/index';
import { connect, useDispatch, useSelector } from 'react-redux';
import { createTaskPromise, deleteTaskPromise, listTasksPromise, listTasksReduxAction, updateTaskPromise, validateTaskPromise } from '../appRedux/actions/Task';
import {
  errTotalHourLabel,
  formatDetailsErrorArrayFromApi,
  getFromDateToDateOfCurrentWeek,
  getUserAuthoried,
  isArrayEqual,
  isLocalhost,
  openNotification,
  removeFieldsNotAllowAddTemplate,
  removeFieldsNotAllowCreate,
  removeFieldsNotAllowUpdate,
  removeFieldsNotAllowValidate,
  timeout,
} from '../util/helper';
import moment from 'moment';
import { createTemplatePromise, listTemplatePromise } from '../appRedux/actions/Template';
import {
  HARDCODE_AATYPE,
  HARDCODE_ACT,
  COLUMNS_KEY_DATE_OF_WEEK,
  COLUMNS_KEY_LABEL_TASK,
  COLUMNS_KEY_TASK,
  DATE_OF_WEEK_NUMBER_MAPPER,
  TABLE_LIST_COLUMN_TASK,
  getSettingColumnDisplay,
} from '../app.const';
import ScannerComponent from '../components/Scanner/Scanner';
const ACTION_CREATE = 'ACTION_CREATE';
const ACTION_UPDATE = 'ACTION_UPDATE';
const ACTION_DELETE = 'ACTION_DELETE';
class HomePage extends React.Component {
  constructor(props) {
    const fromToDate = getFromDateToDateOfCurrentWeek(true);
    super(props);
    this.state = {
      isExpandRight: false,
      showRightBlock: true,
      currentTask: null,
      listTask: [],
      columnDisplay: getSettingColumnDisplay(),
      tmpColumnDisplay: [],
      showModalDisplayColumn: false,
      showModalAddToTemplate: false,
      showModalDeleteTask: false,
      showModalScanSignal: false,
      showModalUseTemplate: false,
      fromDate: fromToDate.fromDate,
      toDate: fromToDate.toDate,
      action: '',
      blockUi: false,
      loading: false,
      authUser: getUserAuthoried() || null,
    };
  }
  startBlockUi = () => {
    this.setState({
      blockUi: true,
    });
  };
  stopBlockUi = () => {
    this.setState({
      blockUi: false,
    });
  };

  setStateAsync(stateUpdate) {
    return new Promise((resolve) => {
      this.setState(stateUpdate, () => resolve());
    });
  }

  async componentDidMount() {
    await this.getDataTasks();
  }

  getDataTasks = async () => {
    let authUser = this.state.authUser;
    let isAdmin = authUser && authUser.username == 'admin';
    if (isAdmin) {
      return message.error('Admin can not call dayforce api');
    }
    let listTask = this.state.listTask || [];
    let listNewTaskTemplate = listTask.filter((r) => {
      return r.isNew && r.isLoadFromTemplate;
    });
    this.startBlockUi();
    try {
      let items = await listTasksPromise({
        fromDate: this.state.fromDate.format('YYYY-MM-DD'),
        toDate: this.state.toDate.format('YYYY-MM-DD'),
      });
      await this.setStateAsync({
        // auto hard act = ACT10
        listTask: [...listNewTaskTemplate, ...items].map((r) => {
          r[COLUMNS_KEY_TASK.Act] = HARDCODE_ACT;
          return r;
        }),
      });
    } catch (error) {
      message.error(error.message || JSON.stringify(error));
    }
    this.stopBlockUi();
  };

  async componentWillReceiveProps(nextProps) {}

  toggeExpanRight = () => {
    let t = this;
    let isExpandRight = t.state.isExpandRight;
    t.setState({
      isExpandRight: !isExpandRight,
      showRightBlock: false,
    });
    setTimeout(() => {
      t.setState({
        showRightBlock: isExpandRight == true ? true : false,
      });
    }, 301);
  };
  submitSelectShowColumn = () => {
    let columnDisplay = _.cloneDeep(this.state.tmpColumnDisplay);
    this.setState({
      showModalDisplayColumn: false,
      columnDisplay: columnDisplay,
    });
  };
  showModalSelectColumnTaskTableHandler = () => {
    this.setState({
      showModalDisplayColumn: true,
      tmpColumnDisplay: _.cloneDeep(this.state.columnDisplay),
    });
  };
  isShowColumn = (columnKey) => {
    let { columnDisplay } = this.state;
    let find = _.find(columnDisplay, { columnKey, isShow: true });
    return !!find;
  };
  isShowColumnDateOfWeek = (columnKey) => {
    return true;
  };
  getColumnNameByKey = (columnKey) => {
    for (const k in COLUMNS_KEY_TASK) {
      if (columnKey == COLUMNS_KEY_TASK[k]) return k;
    }
    return '';
  };
  unselectSelectAll = (type = 'CHECK') => {
    let tmpColumnDisplay = this.state.tmpColumnDisplay;
    tmpColumnDisplay = tmpColumnDisplay.map((r) => {
      r.isShow = type == 'CHECK' ? true : false;
      return r;
    });
    this.setState({
      tmpColumnDisplay,
    });
  };
  showModalAddToTemplate = async (task, idx) => {
    this.setState({
      currentTask: _.cloneDeep(task),
      showModalAddToTemplate: true,
    });
  };
  showModalDeleteTaskHandler = async (task, idx) => {
    this.setState({
      currentTask: _.cloneDeep(task),
      showModalDeleteTask: true,
      action: ACTION_DELETE,
    });
  };
  showModalEditTaskHandler = async (task, idx) => {
    let { listTask, currentTask } = this.state;
    if (currentTask && currentTask.isNew && currentTask.id != task.id) {
      Modal.confirm({
        title: 'Do you want to cancel create new task ?',
        content: 'You are doing create a new task, when click OK new task value will be lost.',
        onOk: async () => {
          await this.resetErrorsListTask();
          await this.setState({
            currentTask: _.cloneDeep(task),
            listTask: listTask.filter((task) => !task.isNew),
            action: ACTION_UPDATE,
          });
        },
        onCancel: () => {
          message.success('Please continue fill values and submit create a new task!');
        },
      });
      return;
    }
    await this.resetErrorsListTask();
    await this.setState({
      currentTask: _.cloneDeep(task),
      listTask,
      action: ACTION_UPDATE,
    });
    // console.log(`click edit task`, task, idx);
  };
  showModalScanSignalHandler = (v = true) => {
    if (v == true) {
      this.setState({
        showModalScanSignal: true,
      });
    } else {
      this.closeAllModal();
    }
  };
  showModalUseTemplateHandler = (v = true) => {
    let listTask = this.state.listTask;
    //check xem co isNew nao chua
    let haveIsNew = listTask.filter((r) => r.isNew == true).length > 0;
    if (haveIsNew == true) {
      message.error('Already have a temporary task, please review !');
      return;
    }
    if (v == true) {
      this.setState({
        showModalUseTemplate: true,
      });
    } else {
      this.closeAllModal();
    }
  };
  clickAddNewTask = async () => {
    let listTask = this.state.listTask;
    if (listTask.filter((r) => r.isNew).length >= 1) {
      message.error('New task already added. Please review and submit it ! ');
      return;
    }
    let task = {
      isNew: true,
      id: uuid.v4(),
    };
    Object.keys(COLUMNS_KEY_TASK).forEach((k) => {
      task[k] = '';
    });
    task = {
      ...task,
      [COLUMNS_KEY_TASK.AAType]: HARDCODE_AATYPE,
      [COLUMNS_KEY_TASK.Act]: HARDCODE_ACT,
      //TEST
      // "employeePayAdjustXRefCode": "#DF_647773",
      // employeeXRefCode: "2641826",
      // payAdjustmentCodeXRefCode: "WRK",
      // orgUnitXRefCode: "USA_WT02_OU1_10020789_10020877_1A0D0000",
      // "payAdjustmentDate": "2023-11-05T00:00:00",
      // "hours": 11.5,
      ...(isLocalhost()
        ? {
            actType: '3032U',
            network: 'NOPOST',
            wbsElement: 'JBEALTSTCER_01.01',
            workCenter: '011HWEC',
          }
        : {}),
    };
    listTask = [task, ...listTask];
    await this.setStateAsync({
      listTask,
      currentTask: _.cloneDeep(task),
      action: ACTION_CREATE,
    });
    // message.success("Temporary add new task successfully. Please review and submit this task! ");
  };
  scanCompleteGotNewTask = async (taskScanned = {}) => {
    let listTask = this.state.listTask;
    if (listTask.filter((r) => r.isNew).length >= 1) {
      message.error('New task already added. Please review and submit it ! ');
      return;
    }
    let task = {
      isNew: true,
      id: uuid.v4(),
    };
    Object.keys(COLUMNS_KEY_TASK).forEach((k) => {
      task[k] = '';
    });
    task = {
      ...task,
      ...taskScanned,
      [COLUMNS_KEY_TASK.AAType]: HARDCODE_AATYPE,
      [COLUMNS_KEY_TASK.Act]: HARDCODE_ACT,
    };
    listTask = [task, ...listTask];
    await this.setStateAsync({
      listTask,
      currentTask: _.cloneDeep(task),
      action: ACTION_CREATE,
    });
    message.success(`Temp task has been added, please review and input hour in this task !`);
  };

  getTextHourOfTask = (task, weekdayKey = COLUMNS_KEY_DATE_OF_WEEK.Mon) => {
    let hours = '';
    let payAdjustmentDate = task.payAdjustmentDate;
    if (!payAdjustmentDate) return '';
    let weekdayOfTask = moment(payAdjustmentDate).weekday();

    for (let cof of DATE_OF_WEEK_NUMBER_MAPPER) {
      if (cof[0] == weekdayKey && cof[1] == weekdayOfTask) {
        hours = task.hours;
        break;
      }
    }
    return hours || '';
  };
  getTextRangeFromToDate = () => {
    const { fromDate, toDate } = this.state;
    return fromDate.format('MM/DD/YYYY') + ' - ' + toDate.format('MM/DD/YYYY');
  };

  checkIsHaveChangeData = (editedObject, objB) => {
    // console.log("editedObject", editedObject);
    let isNew = editedObject.isNew;
    let haveChange = false;
    let keys = Object.keys(editedObject);
    for (let k of keys) {
      if (k == 'isNew' || k == 'id' || k == 'isError' || k == 'errors') continue;
      if (typeof editedObject[k] != 'function') {
        if (editedObject[k] !== objB[k] || (isNew && editedObject[k])) {
          haveChange = true;
        }
      }
    }
    return haveChange;
  };

  resetErrorsListTask = async () => {
    let listTask = this.state.listTask;
    await this.setStateAsync({
      listTask: listTask.map((r) => {
        r.isError = false;
        r.errors = null;
        return r;
      }),
    });
  };

  closeEditOnOneRow = async (idx) => {
    let self = this;
    let currentTask = this.state.currentTask;
    let listTask = this.state.listTask;
    let isNew = currentTask.isNew;
    let haveChange = this.checkIsHaveChangeData(currentTask, listTask[idx]);
    if (!haveChange) {
      if (currentTask.isNew) {
        listTask.splice(idx, 1);
        await this.setStateAsync({
          listTask,
          currentTask: null,
          action: '',
        });
      } else {
        // cancel update => reset origin data row
        await this.setStateAsync({
          currentTask: null,
          action: '',
        });
      }
      await this.resetErrorsListTask();
      this.closeAllModal();

      return;
    }
    Modal.confirm({
      title: isNew ? 'Do you want to remove temporary task ?' : 'Do you want to discard change for this task ?',
      onOk: async (e) => {
        // new task => remove row
        if (currentTask.isNew) {
          listTask.splice(idx, 1);
          await this.setStateAsync({
            listTask,
            currentTask: null,
            action: '',
          });
        } else {
          // cancel update => reset origin data row
          await this.setStateAsync({
            currentTask: null,
            action: '',
          });
        }
        await self.resetErrorsListTask();
        this.closeAllModal();
      },
      onCancel: async (e) => {
        // this.closeAllModal();
        Modal.destroyAll();
      },
    });
  };
  closeEditTaskTemplateOneRow = async (idx) => {
    // console.log(`remove idx`, idx);
    let self = this;
    let listTask = this.state.listTask;
    let task = this.state.listTask[idx];
    let isNew = task.isNew;
    let isLoadFromTemplate = task.isLoadFromTemplate;
    if (isNew && isLoadFromTemplate) {
      Modal.confirm({
        title: 'Do you want to remove temporary task ?',
        onOk: async (e) => {
          // new task => remove row
          await self.setStateAsync({
            listTask: [],
          });
          let newList = [...listTask];
          newList.splice(idx, 1);
          await self.setStateAsync({
            listTask: [...newList],
          });
          await self.resetErrorsListTask();
          self.closeAllModal();
        },
        onCancel: async (e) => {
          // this.closeAllModal();
          Modal.destroyAll();
        },
      });
    }
  };
  submitDeleteTask = async () => {
    await this.setStateAsync({
      showModalDeleteTask: false,
    });
    let currentTask = this.state.currentTask;
    let listTask = this.state.listTask;
    let findIdx = _.findIndex(listTask, { id: currentTask.id });
    if (currentTask.isNew && findIdx >= 0) {
      listTask.splice(findIdx, 1);
      this.setState({
        currentTask: null,
        listTask,
        action: '',
      });
      message.success('Delete temporary task successfully');
    } else {
      this.startBlockUi();
      try {
        await deleteTaskPromise(encodeURIComponent(currentTask.employeePayAdjustXRefCode));
        await this.getDataTasks();
        message.success('Delete task successfully');
      } catch (error) {
        console.error(error);
        message.error(error.message);
      }
      this.stopBlockUi();
    }
    this.closeAllModal();
  };
  showNotificationValidateTask = (error) => {
    let errorsArr = error.details
      ? formatDetailsErrorArrayFromApi(error)
      : error.response && error.response.processResults
        ? error.response.processResults
        : [
            {
              message: error.message,
            },
          ];
    openNotification({
      type: 'error',
      title: 'Validate fail',
      description: (
        <div>
          {errorsArr.map((r, idx) => {
            return (
              <span className="d-block" key={idx}>
                {r.message}
              </span>
            );
          })}
        </div>
      ),
    });
  };
  setRowError = async (error, idxNewTaskTemplate = null) => {
    let listTask = this.state.listTask;
    let errorsArr = error.details
      ? formatDetailsErrorArrayFromApi(error)
      : error.response && error.response.processResults
        ? error.response.processResults
        : [
            {
              message: error.message,
            },
          ];
    let currentTask = typeof idxNewTaskTemplate == 'number' && idxNewTaskTemplate >= 0 ? this.state.listTask[idxNewTaskTemplate] : this.state.currentTask;
    listTask = listTask.map((r) => {
      r.isError = false;
      r.errors = null;
      return r;
    });
    let findIdx = _.findIndex(listTask, { id: currentTask.id });
    if (findIdx >= 0) {
      listTask[findIdx].isError = true;
      listTask[findIdx].errors = errorsArr;
      await this.setStateAsync({
        listTask,
      });
    }
  };
  submitSaveNewTaskTemplateOneRow = async (task, idx) => {
    let listTask = this.state.listTask;
    let loading = this.state.loading;
    let isNew = task.isNew;
    let isLoadFromTemplate = task.isLoadFromTemplate;
    if (isNew && isLoadFromTemplate) {
      Modal.confirm({
        title: 'Create task',
        content: 'Do you want to create new task',
        onOk: async (e) => {
          if (loading == true) return;
          let paramCreate = {
            ...task,
          };
          const isOver24hResult = this.isOver24HPerDate(paramCreate);
          if (isOver24hResult.isOver == true) {
            let errTotalHour = errTotalHourLabel(isOver24hResult.date);
            this.showNotificationValidateTask(errTotalHour);
            this.setRowError(errTotalHour, idx);
            return;
          }
          paramCreate = removeFieldsNotAllowCreate(paramCreate);
          //Note k call validate task nua
          // try {
          //   await this.setStateAsync({ loading: true, blockUi: true });
          //   await validateTaskPromise(paramCreate);
          // } catch (error) {
          //   console.log(error);
          //   this.showNotificationValidateTask(error);
          //   this.setRowError(error, idx);
          //   await this.setStateAsync({ loading: false, blockUi: false });
          //   return;
          // }

          try {
            await this.setStateAsync({ loading: true, blockUi: true });
            let createdId = await createTaskPromise(paramCreate);
            console.log('created new task template', createdId);
            listTask[idx].id = createdId;
            listTask[idx].employeePayAdjustXRefCode = createdId;
            delete listTask[idx].isNew;
            delete listTask[idx].isLoadFromTemplate;

            await this.setStateAsync({
              listTask,
            });
            await this.getDataTasks();
            await this.resetErrorsListTask();
            message.success('Create new task successfully ' + createdId);
          } catch (error) {
            this.showNotificationValidateTask(error);
            this.setRowError(error, idx);
            console.error(error);
            message.error(error.message || JSON.stringify(error));
          }
          this.closeAllModal();
          await this.setStateAsync({ loading: false, blockUi: false });
        },
        onCancel: async () => {
          Modal.destroyAll();
          await this.setStateAsync({ loading: false, blockUi: false });
        },
      });
    }
  };
  submitSaveEditOnOneRow = async () => {
    let currentTask = this.state.currentTask;
    let loading = this.state.loading;
    let listTask = this.state.listTask;
    let isSubmitNewTask = currentTask.isNew;
    const funUpdateTask = async () => {
      let id = encodeURIComponent(currentTask.employeePayAdjustXRefCode || currentTask.id);
      let paramUpdate = removeFieldsNotAllowUpdate({
        ...currentTask,
      });
      console.log(`before update`, { currentTask, paramUpdate });
      //Note k call validate task nua
      // try {
      //   await validateTaskPromise(removeFieldsNotAllowValidate(paramUpdate));
      // } catch (error) {
      //   this.showNotificationValidateTask(error);
      //   this.setRowError(error);
      //   return;
      // }
      try {
        await updateTaskPromise(id, paramUpdate);
        message.success('Update task successfully');
        await this.resetErrorsListTask();
        await this.getDataTasks();
      } catch (error) {
        console.error(error);
        if (error.details) {
          this.showNotificationValidateTask(error);
          this.setRowError(error);
        } else {
          message.error(error.message || JSON.stringify(error));
        }
        return;
      }
      this.closeAllModal();
    };
    const funCreateNewtask = async () => {
      let paramCreate = removeFieldsNotAllowCreate({
        ...currentTask,
      });
      console.log(`before create `, { paramCreate, currentTask });
      //Note k call validate task nua
      // try {
      //   await validateTaskPromise(paramCreate);
      // } catch (error) {
      //   this.showNotificationValidateTask(error);
      //   this.setRowError(error);
      //   return;
      // }

      try {
        await createTaskPromise(paramCreate);
        await this.resetErrorsListTask();
        await this.getDataTasks();
        message.success('Create new task successfully');
      } catch (error) {
        console.error(error);
        if (error.details) {
          this.showNotificationValidateTask(error);
          this.setRowError(error);
        } else {
          message.error(error.message || JSON.stringify(error));
        }
        return;
      }
      this.closeAllModal();
    };

    const isOver24hResult = this.isOver24HPerDate({
      ...currentTask,
    });
    if (isOver24hResult.isOver == true) {
      const errTotalHour = errTotalHourLabel(isOver24hResult.date);
      this.showNotificationValidateTask(errTotalHour);
      this.setRowError(errTotalHour);
      return;
    }
    // neu la new task thi k can show popup
    if (isSubmitNewTask) {
      if (loading === true) return;
      await this.setStateAsync({ loading: true, blockUi: true });
      await funCreateNewtask();
      await this.setStateAsync({ loading: false, blockUi: false });
      return;
    }

    //update thi show modal confirm
    Modal.confirm({
      title: 'Update task',
      content: 'Do you want to update this task',
      onOk: async (e) => {
        if (loading === true) return;
        await this.setStateAsync({ loading: true, blockUi: true });
        await funUpdateTask();
        await this.setStateAsync({ loading: false, blockUi: false });
      },
      onCancel: async () => {
        // this.closeAllModal();
        Modal.destroyAll();
        await this.setStateAsync({ loading: false, blockUi: false });
      },
    });
  };
  submitAddNewTaskToTemplate = async (task, idx) => {
    this.setState({ showModalAddToTemplate: false });
    this.startBlockUi();
    try {
      let res = await createTemplatePromise({
        templates: [removeFieldsNotAllowAddTemplate(task)],
      });
      // console.log("create template res = ", res);
      message.success('Add this task to template successfully');
    } catch (error) {
      console.error(error);
      message.error(error.message || JSON.stringify(error));
    }
    this.stopBlockUi();
    this.closeAllModal();
  };
  submitUseTemplateAndConvertToTask = async () => {
    // this.setState({
    //   showModalUseTemplate: false,
    // });
    let listTask = this.state.listTask;
    //check xem co isNew nao chua
    let haveIsNew = listTask.filter((r) => r.isNew == true).length > 0;
    if (haveIsNew == true) {
      message.error('Already have a temporary task, please review !');
      return;
    }
    try {
      let templates = await listTemplatePromise({});
      if (!Array.isArray(templates) || templates.length == 0) {
        message.error('Not found any template');
      } else if (Array.isArray(templates) && templates.length > 0) {
        let listTask = this.state.listTask;
        listTask = listTask.map((r) => {
          delete r.isNew;
          return r;
        });
        templates = templates.map((r) => {
          return {
            ...r,
            id: uuid.v4(),
            isNew: true,
            isLoadFromTemplate: true,
            [COLUMNS_KEY_TASK.AAType]: HARDCODE_AATYPE,
            [COLUMNS_KEY_TASK.Act]: HARDCODE_ACT,
          };
        });
        await this.setStateAsync({
          listTask: [...templates, ...listTask],
        });
        message.success(`Load ${templates.length} rows from template success`);
      }
    } catch (error) {
      console.error(error);
      message.error(error.message);
    }
    this.closeAllModal();
  };
  closeAllModal = async () => {
    Modal.destroyAll();
    await this.setStateAsync({
      action: '',
      showModalDisplayColumn: false,
      showModalAddToTemplate: false,
      showModalDeleteTask: false,
      showModalScanSignal: false,
      showModalUseTemplate: false,
      currentTask: null,
    });
  };

  onChangeInputCurrentTask = async (e, aTask, idx, key) => {
    let currentTask = this.state.currentTask;
    currentTask[key] = e.target.value;
    this.setState({
      currentTask,
    });
  };
  onChangeInputNewTaskTemplate = (e, idx, key) => {
    let listTask = this.state.listTask;
    let task = listTask[idx];
    task[key] = e.target.value;
    this.setState({
      listTask,
    });
    // console.log("change value for row new task template", { key, value: e.target.value }, task);
  };
  onChangeHourOfTask = (task, idx, hours, dayOfWeekStringKey = COLUMNS_KEY_DATE_OF_WEEK.Fri) => {
    let fromDate = this.state.fromDate;
    let newFromDate = fromDate.clone();
    let hour = parseFloat(hours);
    let listTask = this.state.listTask;
    let currentTask = task.isLoadFromTemplate ? task : this.state.currentTask;
    currentTask[COLUMNS_KEY_TASK.Hours] = hour;
    let payAdjustmentDate = '';
    const configNumberDateToAdded = [
      [COLUMNS_KEY_DATE_OF_WEEK.Fri, 0],
      [COLUMNS_KEY_DATE_OF_WEEK.Sat, 1],
      [COLUMNS_KEY_DATE_OF_WEEK.Sun, 2],
      [COLUMNS_KEY_DATE_OF_WEEK.Mon, 3],
      [COLUMNS_KEY_DATE_OF_WEEK.Tue, 4],
      [COLUMNS_KEY_DATE_OF_WEEK.Wed, 5],
      [COLUMNS_KEY_DATE_OF_WEEK.Thu, 6],
    ];
    for (let dateAdd of configNumberDateToAdded) {
      if (dateAdd[0] == dayOfWeekStringKey) {
        let addedNumber = dateAdd[1];
        // console.log('added date', addedNumber);
        // console.log('fromdate', fromDate);
        payAdjustmentDate = newFromDate.add(addedNumber, 'days').format('YYYY-MM-DD');
        // console.log(' new date ', payAdjustmentDate);
        break;
      }
    }
    currentTask[COLUMNS_KEY_TASK.PayAdjustmentDate] = payAdjustmentDate;
    // console.log('new current task', currentTask);
    if (task.isLoadFromTemplate) {
      listTask[idx] = currentTask;
      this.setState({
        listTask,
      });
    } else {
      this.setState({
        currentTask: currentTask,
      });
    }
  };
  sumTotalHoursPerDayOfWeek = (dayOfWeek = COLUMNS_KEY_DATE_OF_WEEK.Fri) => {
    let listTask = this.state.listTask;
    let sum = 0;
    for (let task of listTask) {
      let payDate = task.payAdjustmentDate;
      let weekdayNumber = moment(payDate).weekday();
      for (let conf of DATE_OF_WEEK_NUMBER_MAPPER) {
        if (dayOfWeek == conf[0] && weekdayNumber == conf[1]) {
          let hour = parseFloat(task.hours);
          sum += isNaN(hour) ? 0 : hour;
        }
      }
    }
    return sum == 0 ? '(0)' : `(${sum})`;
  };
  tooltipDateLabel = (dayOfWeek = COLUMNS_KEY_DATE_OF_WEEK.Fri) => {
    let fromDate = this.state.fromDate;
    let newFromDate = fromDate.clone();
    let datefinal = fromDate.clone();
    const configNumberDateToAdded = [
      [COLUMNS_KEY_DATE_OF_WEEK.Fri, 0],
      [COLUMNS_KEY_DATE_OF_WEEK.Sat, 1],
      [COLUMNS_KEY_DATE_OF_WEEK.Sun, 2],
      [COLUMNS_KEY_DATE_OF_WEEK.Mon, 3],
      [COLUMNS_KEY_DATE_OF_WEEK.Tue, 4],
      [COLUMNS_KEY_DATE_OF_WEEK.Wed, 5],
      [COLUMNS_KEY_DATE_OF_WEEK.Thu, 6],
    ];
    for (let dateAdd of configNumberDateToAdded) {
      if (dateAdd[0] == dayOfWeek) {
        datefinal = newFromDate.add(dateAdd[1], 'days'); //.format("YYYY-MM-DD");
        break;
      }
    }
    return datefinal.format('YYYY-MM-DD');
  };
  isThisRowNewTaskFromTemplate = (task, idx) => {
    let listTask = this.state.listTask;
    let listTaskTemplate = listTask.filter((r) => {
      return r.isNew == true && r.isLoadFromTemplate == true;
    });
    return task.id && listTaskTemplate.map((r) => r.id).includes(task.id);
  };

  tooltipTaskError = (task) => {
    return (
      <TooltipAntd
        placement="right"
        title={
          <>
            {(task.errors || []).map((err, iiErr) => {
              return (
                <span key={iiErr} className="d-block">
                  {err.message}
                </span>
              );
            })}
          </>
        }
      >
        <i
          className="fa fa-question-circle text-danger pointer"
          style={{
            top: '40%',
            left: 0,
            position: 'absolute',
          }}
        ></i>
      </TooltipAntd>
    );
  };
  checkDisableHourInput = (task, idx, dateOfWeek) => {
    let { listTask, currentTask, fromDate, toDate, action } = this.state;
    let newFromDate = fromDate.clone();
    const isEditOnRow = action == ACTION_UPDATE && currentTask && task.id == currentTask.id && !task.isNew;
    const isAddOnRow = action == ACTION_CREATE && currentTask && task.id == currentTask.id && task.isNew;
    const isShowInputForm = currentTask && (isEditOnRow || isAddOnRow);
    const isNewTaskTemplate = this.isThisRowNewTaskFromTemplate(task, idx);
    if (isAddOnRow) return false;
    if (isNewTaskTemplate) return false;
    // console.log(`idx`, idx, task);
    if (isEditOnRow && currentTask) {
      let taskPayAdjustmentDate = moment(currentTask.payAdjustmentDate).format('YYYY-MM-DD');
      let payAdjustmentDate = '';
      const configNumberDateToAdded = [
        [COLUMNS_KEY_DATE_OF_WEEK.Fri, 0],
        [COLUMNS_KEY_DATE_OF_WEEK.Sat, 1],
        [COLUMNS_KEY_DATE_OF_WEEK.Sun, 2],
        [COLUMNS_KEY_DATE_OF_WEEK.Mon, 3],
        [COLUMNS_KEY_DATE_OF_WEEK.Tue, 4],
        [COLUMNS_KEY_DATE_OF_WEEK.Wed, 5],
        [COLUMNS_KEY_DATE_OF_WEEK.Thu, 6],
      ];
      for (let dowKey of configNumberDateToAdded) {
        if (dowKey[0] === dateOfWeek) {
          let addedNumber = dowKey[1];
          // console.log(`number add days `, addedNumber);
          payAdjustmentDate = newFromDate.add(addedNumber, 'days').format('YYYY-MM-DD');
          // console.log(`number add days `, {newFromDate,  payAdjustmentDate, taskPayAdjustmentDate});
          break;
        }
      }
      // console.log(`idx`, {taskPayAdjustmentDate, payAdjustmentDate, newFromDate, currentTask} );
      return currentTask.payAdjustmentDate && taskPayAdjustmentDate === payAdjustmentDate ? false : true;
    }
    return false;
  };

  isOver24HPerDate = (currTask = {}) => {
    let { listTask, currentTask, fromDate, toDate, action } = this.state;
    let currDate = String(currTask.payAdjustmentDate).toString().substring(0, 10);
    console.log(`currDate `, { currTask, currDate });
    let currHour = parseFloat(currTask.hours) || 0;
    let totalHour = 0;
    for (let taskk of listTask) {
      if (taskk.id == currTask.id) continue;
      let dateString = String(taskk.payAdjustmentDate).toString().substring(0, 10);
      if (dateString == currDate) {
        let hour = parseFloat(taskk.hours);
        totalHour += isNaN(hour) ? 0 : hour;
      }
    }
    return {
      date: currDate,
      isOver: totalHour + currHour > 24 ? true : false,
    };
  };
  onHandleKeydownOfRow = (e, { enterKey, escKey }) => {
    // {
    //   enterKey: ()=>{
    //     isNewTaskTemplate ? this.submitSaveNewTaskTemplateOneRow(task, idx) : this.submitSaveEditOnOneRow();
    //   },
    //   escKey: ()=>{
    //     isNewTaskTemplate ? this.closeEditTaskTemplateOneRow(idx) : this.closeEditOnOneRow(idx);
    //   }
    // }
    console.log(`[onHandleKeydownOfRow] on key down`, e.key);
    if ([13, 27].includes(e.keyCode)) {
      console.log(`key down`, e.keyCode, e.key);
      if (e.keyCode === 13) {
        console.log('key Enter');
        enterKey();
      }
      if (e.keyCode === 27) {
        console.log(`key Escape `);
        escKey();
      }
    } else {
      // e.preventDefault();
    }
  };

  render() {
    let {
      isExpandRight,
      showRightBlock,
      listTask,
      columnDisplay,
      tmpColumnDisplay,
      showModalDisplayColumn,
      currentTask,
      showModalAddToTemplate,
      showModalDeleteTask,
      showModalScanSignal,
      fromDate,
      toDate,
      action,
      loading,
    } = this.state;
    let rightCol = isExpandRight ? 0 : 8;
    let leftCol = isExpandRight == false ? 16 : 24;
    // const taskPageState = this.props.taskPage;
    // const loading = taskPageState.loading;
    const isUpsertOnRowMode = currentTask && currentTask.id && [ACTION_CREATE, ACTION_UPDATE].includes(action);
    const isAddRowsByTemplateMode = listTask.filter((r) => r.isNew && r.isLoadFromTemplate).length > 0;
    const isEditMode = isUpsertOnRowMode || isAddRowsByTemplateMode;

    return (
      <Auxiliary>
        <div className="timesheetpage  ant-card">
          <div className=" ant-card-body">
            <BlockUi tag="div" blocking={this.state.blockUi}>
              <div className={`timesheet`}>
                <Row>
                  <Col xl={leftCol} lg={leftCol} md={leftCol} sm={leftCol} xs={leftCol}>
                    <label className="text-primary label-text-task mr-2">DATE</label>
                    {/* <span className="w-100">
                    <DatePicker
                      onChange={(date, dateString) => {
                        console.log(date, dateString);
                      }}
                      picker="week"
                      className="mr-2"
                      format={"YYYY-MM-DD"}
                      style={{ verticalAlign: "super" }}
                    />
                  </span>
                   */}
                    <input className="form-control input-select-weekdate" disabled value={this.getTextRangeFromToDate()} />
                    <span className="pull-right list-button-block">
                      <button
                        className="btn btn-primary app-button"
                        onClick={(e) => {
                          this.showModalScanSignalHandler(true);
                        }}
                      >
                        QR Scan
                      </button>
                      <button
                        className="btn btn-primary app-button"
                        onClick={(e) => {
                          this.clickAddNewTask();
                        }}
                      >
                        New Line
                      </button>
                      <button
                        className="btn btn-primary app-button"
                        onClick={(e) => {
                          // this.props.router.history.push('/template');
                          this.props.history.push('template');
                        }}
                      >
                        Manage Template
                      </button>
                      <button
                        className="btn btn-primary app-button"
                        onClick={(e) => {
                          this.showModalUseTemplateHandler(true);
                        }}
                      >
                        Apply Template
                      </button>
                    </span>
                  </Col>
                </Row>

                <Row>
                  <Col className="timesheet-left" style={{ transition: 'all 0.3s ease', position: 'relative' }} xl={leftCol} lg={leftCol} md={leftCol} sm={leftCol} xs={leftCol}></Col>
                  <Col className="timesheet-right" style={{ transition: 'all 0.3s ease', transitionDelay: '0.2s' }} xl={rightCol} lg={rightCol} md={rightCol} sm={rightCol} xs={rightCol}></Col>
                </Row>
                <Row style={{ overflowX: 'hidden' }}>
                  <Col style={{ transition: 'all 0.3s ease', position: 'relative' }} xl={leftCol} lg={leftCol} md={leftCol} sm={leftCol} xs={leftCol}>
                    {/* <i
                    className={` icon-arrow-expand icon icon-arrow-${isExpandRight ? "left" : "right"} pointer`}
                    onClick={(e) => {
                      this.toggeExpanRight();
                    }}
                  ></i> */}

                    <div className="table-responsive">
                      <table className={`table table-task-left ${isEditMode ? ' open-edit-mode ' : ''}`}>
                        <thead className=" ">
                          <tr>
                            {TABLE_LIST_COLUMN_TASK.map((aColumn, idx) => {
                              return (
                                this.isShowColumn(aColumn.key) && (
                                  <th key={idx} className={`${idx == 0 ? 'first' : ''}`}>
                                    <span>{aColumn.label}</span>
                                  </th>
                                )
                              );
                            })}

                            {/* 
                            {this.isShowColumn(COLUMNS_KEY_TASK.Plant) && (
                              <th>
                                <span>
                                  {COLUMNS_KEY_LABEL_TASK[COLUMNS_KEY_TASK.Plant]} <i className="fa fa-arrow-down"></i>
                                </span>
                              </th>
                            )}
                            */}

                            <th className="last">
                              <i
                                className="fa fa-ellipsis-v text-primary"
                                onClick={(e) => {
                                  this.showModalSelectColumnTaskTableHandler();
                                }}
                              ></i>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {listTask.map((task, idx) => {
                            const isEditOnRow = action == ACTION_UPDATE && currentTask && task.id == currentTask.id && !task.isNew;
                            const isAddOnRow = action == ACTION_CREATE && currentTask && task.id == currentTask.id && task.isNew;
                            const isShowInputForm = currentTask && (isEditOnRow || isAddOnRow);
                            const isNewTaskTemplate = this.isThisRowNewTaskFromTemplate(task, idx);
                            // console.log(idx, task);
                            // console.log('currentTask', currentTask);
                            return (
                              <tr
                                key={idx}
                                style={{ position: 'relative' }}
                                className={`${idx == 0 ? ' first-row ' : ''}  ${idx == listTask.length - 1 ? ' last-row ' : ''} 
                            ${isEditOnRow ? ' current-task ' : isAddOnRow || isNewTaskTemplate ? ' on-new-task ' : ''}
                            ${task.isError ? ' error ' : ''}
                            `}
                              >
                                {TABLE_LIST_COLUMN_TASK.map((aColumn, idxCol) => {
                                  return (
                                    this.isShowColumn(aColumn.key) && (
                                      <td key={idxCol}>
                                        {idxCol == 0 && task.isError && this.tooltipTaskError(task)}
                                        {isShowInputForm || isNewTaskTemplate ? (
                                          <input
                                            className="form-control input-allow-change-value"
                                            disabled={aColumn.isDisableInput}
                                            onChange={(e) => {
                                              isNewTaskTemplate ? this.onChangeInputNewTaskTemplate(e, idx, aColumn.key) : this.onChangeInputCurrentTask(e, currentTask, idx, aColumn.key);
                                            }}
                                            value={isNewTaskTemplate ? task[aColumn.key] : currentTask[aColumn.key]}
                                            onKeyDown={(e) =>
                                              this.onHandleKeydownOfRow(e, {
                                                enterKey: () => {
                                                  isNewTaskTemplate ? this.submitSaveNewTaskTemplateOneRow(task, idx) : this.submitSaveEditOnOneRow();
                                                },
                                                escKey: () => {
                                                  isNewTaskTemplate ? this.closeEditTaskTemplateOneRow(idx) : this.closeEditOnOneRow(idx);
                                                },
                                              })
                                            }
                                          />
                                        ) : (
                                          <span>{task[aColumn.key]}</span>
                                        )}
                                      </td>
                                    )
                                  );
                                })}

                                {/*
                                {this.isShowColumn(COLUMNS_KEY_TASK.Plant) && (
                                  <td>
                                    {isShowInputForm || isNewTaskTemplate ? (
                                      <input
                                        className="form-control input-allow-change-value"
                                        onChange={(e) => {
                                          isNewTaskTemplate
                                            ? this.onChangeInputNewTaskTemplate(e, idx, COLUMNS_KEY_TASK.Plant)
                                            : this.onChangeInputCurrentTask(e, currentTask, idx, COLUMNS_KEY_TASK.Plant);
                                        }}
                                        value={isNewTaskTemplate ? task[COLUMNS_KEY_TASK.Plant] : currentTask[COLUMNS_KEY_TASK.Plant]}
                                      />
                                    ) : (
                                      <span>{task[COLUMNS_KEY_TASK.Plant]}</span>
                                    )}
                                  </td>
                                )}
                                {this.isShowColumn(COLUMNS_KEY_TASK.WorkCenter) && (
                                  <td>
                                    {isShowInputForm || isNewTaskTemplate ? (
                                      <input
                                        className="form-control input-allow-change-value"
                                        onChange={(e) => {
                                          isNewTaskTemplate
                                            ? this.onChangeInputNewTaskTemplate(e, idx, COLUMNS_KEY_TASK.WorkCenter)
                                            : this.onChangeInputCurrentTask(e, currentTask, idx, COLUMNS_KEY_TASK.WorkCenter);
                                        }}
                                        value={isNewTaskTemplate ? task[COLUMNS_KEY_TASK.WorkCenter] : currentTask[COLUMNS_KEY_TASK.WorkCenter]}
                                      />
                                    ) : (
                                      <span>{task[COLUMNS_KEY_TASK.WorkCenter]}</span>
                                    )}
                                  </td>
                                )}
                                */}
                                <td className="td-action">
                                  {isShowInputForm || isNewTaskTemplate ? (
                                    <span className="task-action">
                                      <i
                                        className="fa fa-check pointer text-primary mr-2"
                                        onClick={(e) => {
                                          isNewTaskTemplate ? this.submitSaveNewTaskTemplateOneRow(task, idx) : this.submitSaveEditOnOneRow();
                                        }}
                                      ></i>
                                      <i
                                        className="fa fa-close pointer"
                                        onClick={(e) => {
                                          isNewTaskTemplate ? this.closeEditTaskTemplateOneRow(idx) : this.closeEditOnOneRow(idx);
                                        }}
                                      ></i>
                                    </span>
                                  ) : (
                                    <span className="task-action">
                                      <Dropdown
                                        className="dotdotdot"
                                        trigger={'hover'}
                                        overlay={
                                          <Menu>
                                            <Menu.Item key="edit">
                                              <span
                                                className="w-100"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.showModalEditTaskHandler(task, idx);
                                                }}
                                              >
                                                Edit
                                              </span>
                                            </Menu.Item>
                                            <Menu.Item key="add">
                                              <span
                                                className="w-100"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.showModalAddToTemplate(task, idx);
                                                }}
                                              >
                                                Add to template
                                              </span>
                                            </Menu.Item>
                                            <Menu.Item key="delete">
                                              <span
                                                className="text-danger w-100"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.showModalDeleteTaskHandler(task, idx);
                                                }}
                                              >
                                                Delete
                                              </span>
                                            </Menu.Item>
                                          </Menu>
                                        }
                                      >
                                        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                                          <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                        </a>
                                      </Dropdown>
                                    </span>
                                  )}
                                </td>
                                {/* <span style={{position:'absolute', right:0, top:0}}>
                              <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                              </span> */}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </Col>
                  {showRightBlock && (
                    <Col style={{ transition: 'all 0.3s ease', transitionDelay: '0.2s' }} xl={rightCol} lg={rightCol} md={rightCol} sm={rightCol} xs={rightCol}>
                      <div className="table-responsive">
                        <table className="table table-date-of-week-right">
                          <thead className=" ">
                            <tr>
                              {this.isShowColumnDateOfWeek(COLUMNS_KEY_DATE_OF_WEEK.Fri) && (
                                <th className="first line-height-40">
                                  <span className="d-block">
                                    <TooltipAntd placement="top" title={this.tooltipDateLabel(COLUMNS_KEY_DATE_OF_WEEK.Fri)}>
                                      Fri
                                    </TooltipAntd>
                                  </span>
                                  <span className="d-block">{this.sumTotalHoursPerDayOfWeek(COLUMNS_KEY_DATE_OF_WEEK.Fri)}</span>
                                </th>
                              )}

                              {this.isShowColumnDateOfWeek(COLUMNS_KEY_DATE_OF_WEEK.Sat) && (
                                <th className="col-sat line-height-40">
                                  <span className="d-block">
                                    <TooltipAntd placement="top" title={this.tooltipDateLabel(COLUMNS_KEY_DATE_OF_WEEK.Sat)}>
                                      Sat
                                    </TooltipAntd>
                                  </span>
                                  <span className="d-block">{this.sumTotalHoursPerDayOfWeek(COLUMNS_KEY_DATE_OF_WEEK.Sat)}</span>
                                </th>
                              )}
                              {this.isShowColumnDateOfWeek(COLUMNS_KEY_DATE_OF_WEEK.Sun) && (
                                <th className="col-sun line-height-40">
                                  <span className="d-block">
                                    <TooltipAntd placement="top" title={this.tooltipDateLabel(COLUMNS_KEY_DATE_OF_WEEK.Sun)}>
                                      Sun
                                    </TooltipAntd>
                                  </span>
                                  <span className="d-block">{this.sumTotalHoursPerDayOfWeek(COLUMNS_KEY_DATE_OF_WEEK.Sun)}</span>
                                </th>
                              )}
                              {this.isShowColumnDateOfWeek(COLUMNS_KEY_DATE_OF_WEEK.Mon) && (
                                <th className="line-height-40">
                                  <span className="d-block">
                                    <TooltipAntd placement="top" title={this.tooltipDateLabel(COLUMNS_KEY_DATE_OF_WEEK.Mon)}>
                                      Mon
                                    </TooltipAntd>
                                  </span>
                                  <span className="d-block">{this.sumTotalHoursPerDayOfWeek(COLUMNS_KEY_DATE_OF_WEEK.Mon)}</span>
                                </th>
                              )}
                              {this.isShowColumnDateOfWeek(COLUMNS_KEY_DATE_OF_WEEK.Tue) && (
                                <th className="line-height-40">
                                  <span className="d-block">
                                    <TooltipAntd placement="top" title={this.tooltipDateLabel(COLUMNS_KEY_DATE_OF_WEEK.Tue)}>
                                      Tue
                                    </TooltipAntd>
                                  </span>
                                  <span className="d-block">{this.sumTotalHoursPerDayOfWeek(COLUMNS_KEY_DATE_OF_WEEK.Tue)}</span>
                                </th>
                              )}
                              {this.isShowColumnDateOfWeek(COLUMNS_KEY_DATE_OF_WEEK.Wed) && (
                                <th className="line-height-40">
                                  <span className="d-block">
                                    <TooltipAntd placement="top" title={this.tooltipDateLabel(COLUMNS_KEY_DATE_OF_WEEK.Wed)}>
                                      Wed
                                    </TooltipAntd>
                                  </span>
                                  <span className="d-block">{this.sumTotalHoursPerDayOfWeek(COLUMNS_KEY_DATE_OF_WEEK.Wed)}</span>
                                </th>
                              )}
                              {this.isShowColumnDateOfWeek(COLUMNS_KEY_DATE_OF_WEEK.Thu) && (
                                <th className="line-height-40">
                                  <span className="d-block">
                                    <TooltipAntd placement="top" title={this.tooltipDateLabel(COLUMNS_KEY_DATE_OF_WEEK.Thu)}>
                                      Thu
                                    </TooltipAntd>
                                  </span>
                                  <span className="d-block">{this.sumTotalHoursPerDayOfWeek(COLUMNS_KEY_DATE_OF_WEEK.Thu)}</span>
                                </th>
                              )}
                              {/* <th className="last total-hour">
                              <span>Total</span>
                            </th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {listTask.map((task, idx) => {
                              const isEditOnRow = action == ACTION_UPDATE && currentTask && task.id == currentTask.id && !task.isNew;
                              const isAddOnRow = action == ACTION_CREATE && currentTask && task.id == currentTask.id && task.isNew;
                              const isShowInputForm = currentTask && (isEditOnRow || isAddOnRow);
                              const isNewTaskTemplate = this.isThisRowNewTaskFromTemplate(task, idx);
                              return (
                                <tr
                                  key={idx}
                                  style={{ position: 'relative' }}
                                  className={`${idx == 0 ? ' first-row ' : ''}  ${idx == listTask.length - 1 ? ' last-row ' : ''} 
                                ${isEditOnRow ? ' current-task ' : isAddOnRow || isNewTaskTemplate ? ' on-new-task ' : ''}
                                ${task.isError ? ' error ' : ''}
                            `}
                                >
                                  {[
                                    {
                                      key: COLUMNS_KEY_DATE_OF_WEEK.Fri,
                                      className: '',
                                    },
                                    {
                                      key: COLUMNS_KEY_DATE_OF_WEEK.Sat,
                                      className: 'col-sat',
                                    },
                                    {
                                      key: COLUMNS_KEY_DATE_OF_WEEK.Sun,
                                      className: 'col-sun',
                                    },
                                    {
                                      key: COLUMNS_KEY_DATE_OF_WEEK.Mon,
                                      className: '',
                                    },
                                    {
                                      key: COLUMNS_KEY_DATE_OF_WEEK.Tue,
                                      className: '',
                                    },
                                    {
                                      key: COLUMNS_KEY_DATE_OF_WEEK.Wed,
                                      className: '',
                                    },
                                    {
                                      key: COLUMNS_KEY_DATE_OF_WEEK.Thu,
                                      className: '',
                                    },
                                  ].map((aColumnObj, idxColumn) => {
                                    return (
                                      this.isShowColumnDateOfWeek(aColumnObj.key) && (
                                        <td key={idxColumn} className={aColumnObj.className}>
                                          {isShowInputForm || isNewTaskTemplate ? (
                                            <InputNumber
                                              controls={false}
                                              min={0}
                                              max={24}
                                              className="form-control input-allow-change-value"
                                              onChange={(value) => {
                                                console.log(`on change Input hour`, value);
                                                this.onChangeHourOfTask(isNewTaskTemplate ? task : currentTask, idx, value, aColumnObj.key);
                                              }}
                                              disabled={this.checkDisableHourInput(task, idx, aColumnObj.key)}
                                              value={this.getTextHourOfTask(isNewTaskTemplate ? task : currentTask, aColumnObj.key)}
                                              onKeyDown={(e) => {
                                                console.log(`onKeyDown Input hour`, e);
                                                this.onHandleKeydownOfRow(e, {
                                                  enterKey: () => {
                                                    isNewTaskTemplate ? this.submitSaveNewTaskTemplateOneRow(task, idx) : this.submitSaveEditOnOneRow();
                                                  },
                                                  escKey: () => {
                                                    isNewTaskTemplate ? this.closeEditTaskTemplateOneRow(idx) : this.closeEditOnOneRow(idx);
                                                  },
                                                });
                                              }}
                                            />
                                          ) : (
                                            this.getTextHourOfTask(task, aColumnObj.key)
                                          )}
                                        </td>
                                      )
                                    );
                                  })}
                                  {
                                    // this.isShowColumnDateOfWeek(COLUMNS_KEY_DATE_OF_WEEK.Fri) && (
                                    //   <td>
                                    //     {isShowInputForm || isNewTaskTemplate ? (
                                    //       <input
                                    //         className="form-control input-allow-change-value"
                                    //         onChange={(e) => {
                                    //           this.onChangeHourOfTask(isNewTaskTemplate ? task : currentTask, idx, e.target.value, COLUMNS_KEY_DATE_OF_WEEK.Fri);
                                    //         }}
                                    //         disabled={this.checkDisableHourInput(task, idx, COLUMNS_KEY_DATE_OF_WEEK.Fri)}
                                    //         value={this.getTextHourOfTask(isNewTaskTemplate ? task : currentTask, COLUMNS_KEY_DATE_OF_WEEK.Fri)}
                                    //       />
                                    //     ) : (
                                    //       this.getTextHourOfTask(task, COLUMNS_KEY_DATE_OF_WEEK.Fri)
                                    //     )}
                                    //   </td>
                                    // )
                                  }
                                  {/* <td className="total-hour">{task.total}</td> */}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
            </BlockUi>
          </div>

          <Modal
            title="Select column to display"
            open={showModalDisplayColumn}
            onOk={(e) => {
              this.submitSelectShowColumn();
            }}
            onCancel={(e) => {
              this.closeAllModal();
            }}
          >
            <span className="d-block mb-2">
              <span className="mb-2" style={{ cursor: 'pointer' }} onClick={(e) => this.unselectSelectAll('CHECK')}>
                Select All
              </span>{' '}
              /{' '}
              <span className="mb-2" style={{ cursor: 'pointer' }} onClick={(e) => this.unselectSelectAll('UNCHECK')}>
                Unselect All
              </span>
            </span>

            {tmpColumnDisplay.map((col, idx) => {
              const clickCheck = (e) => {
                col.isShow = !col.isShow;
                tmpColumnDisplay[idx] = col;
                this.setState({
                  tmpColumnDisplay,
                });
              };
              return (
                <div key={idx}>
                  {col.isShow ? <i className="fa fa-check-square text-primary mr-2" onClick={clickCheck}></i> : <i className="fa fa-square-o text-primary mr-2" onClick={clickCheck}></i>}
                  <span>{this.getColumnNameByKey(col.columnKey)}</span>
                </div>
              );
            })}
          </Modal>

          <Modal
            title="Confirm !"
            // centered
            open={showModalAddToTemplate}
            onOk={() => {
              this.submitAddNewTaskToTemplate(this.state.currentTask);
            }}
            onCancel={() => {
              this.closeAllModal();
            }}
            // width={1000}
          >
            <div className="row">
              <div className="col-12 text-center">
                <span className="text-primary">Do you want to add this task to template ?</span>
              </div>
            </div>
          </Modal>

          <Modal
            title={<span className="text-danger">Confirm delete task !</span>}
            // centered
            open={showModalDeleteTask}
            onOk={() => {
              Modal.destroyAll();
              this.submitDeleteTask();
            }}
            onCancel={() => {
              this.closeAllModal();
            }}
            // width={1000}
          >
            <div className="row">
              <div className="col-12 text-center">
                <span className="text-primary">Do you want to delete this task ?</span>
              </div>
            </div>
          </Modal>

          {showModalScanSignal && (
            <Modal
              title={<span className="text font-20">Scanner</span>}
              // centered
              open={true}
              onOk={() => {
                // this.closeAllModal();
              }}
              onCancel={() => {
                this.closeAllModal();
              }}
              cancelButtonProps={{ style: { display: 'none' } }}
              okButtonProps={{ style: { display: 'none' } }}
              width={'450px'}
            >
              <div>
                <ScannerComponent
                  closeModal={async (output = null) => {
                    console.log(`[Homepage] close scanner modal `, output);
                    await this.setStateAsync({
                      showModalScanSignal: false,
                    });
                    if (output && output.task) {
                      await this.scanCompleteGotNewTask(output.task);
                    }
                  }}
                />
              </div>
            </Modal>
          )}
          <Modal
            title={<span className="text font-20">Use Template</span>}
            // centered
            open={this.state.showModalUseTemplate}
            onOk={() => {
              // this.closeAllModal();
              this.submitUseTemplateAndConvertToTask();
            }}
            onCancel={() => {
              this.closeAllModal();
            }}
            // cancelButtonProps={{ style: { display: "none" } }}
            // width={1000}
          >
            <div className="row">
              <div className="col-12 text-center">
                {/* <i className="fa fa-spin fa-spinner  text-primary mr-2"></i> */}
                <span className="text-primary">Do you want to add new task from template ?</span>
              </div>
            </div>
          </Modal>
        </div>
      </Auxiliary>
    );
  }
}

function mapStateToProps(state) {
  return state;
}
export default connect(mapStateToProps, {
  listTasksReduxAction: listTasksReduxAction,
})(HomePage);
