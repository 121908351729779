const isLocal = window.location.host.includes('localhost');
const isTestSSO = localStorage.getItem('testSSO') === 'true';
if (!process.env.REACT_APP_ENV) {
  process.env.REACT_APP_ENV = 'development';
}
const isProd = process.env.REACT_APP_ENV === 'production';

const apiUrl = isProd ? process.env.REACT_APP_API_BASE_URL : 'https://api.transcendteq.com';
const ssoRedirectUrl = !isLocal ?  process.env.REACT_APP_SSO_AZURE_REDIRECT_URL: 'http://localhost:3000/signin';

export const API_URL = apiUrl;
export const ssoAzureClientId = isTestSSO ? '538f9d1d-2a43-4b1f-9bd5-5a4e574dbbe9' : process.env.REACT_APP_SSO_AZURE_CLIENT_ID;
export const ssoAzureTenantId = isTestSSO ? 'a75e333c-b68f-4567-b69c-94ece20a394b' : process.env.REACT_APP_SSO_AZURE_TENANT_ID;
export const ssoAzureRedirectUrl = ssoRedirectUrl;
export const VERSION_APP = process.env.REACT_APP_APP_VERSION;

console.log(`%c env app started`, 'background:green; font-size:20px; color:white; padding:10px', {
  NODE_ENV: process.env.NODE_ENV,
  REACT_APP_ENV: process.env.REACT_APP_ENV,
  API_URL: apiUrl,
  ssoRedirectUrl,
  ssoAzureClientId,
  ssoAzureTenantId,
});

//Login type
export const LOGIN_TYPE_AZURE = 'ssoAzure';
export const LOGIN_TYPE_PASSWORD = 'password';
//azure
export const LOGIN_TYPE_KEY = 'timesheet-login-type';
export const ACCESS_TOKEN_KEY = 'timesheet-access-token';
export const ID_TOKEN_KEY = 'timesheet-id-token';
//password
export const TOKEN_KEY = 'timesheet-token';
export const USER_KEY = 'timesheet-user';

export const footerText = 'Copyright © 2023. Version ' + VERSION_APP;
export const usernameHardCode = '2641826';
export const passwordHardCode = 'RqFukLnEW5mJV4';
export const HARDCODE_AATYPE = 'US - Labor Metrics';
export const HARDCODE_ACT = '0010'; // 'ACT10';

export const COLUMNS_KEY_TASK = {
  //cac col chua co value tu BE
  Act: 'act',
  Plant: 'plant',
  RecCCtrl: 'recCCtr',
  RecOrder: 'recOrder',
  SendCostCenter: 'sendCostCenter',
  AAType: 'aaType',
  TocsID: 'tocsId',

  //cac column da co tu BE
  ActType: 'actType',
  Network: 'network',
  WbsElement: 'wbsElement',
  WorkCenter: 'workCenter',
  Hours: 'hours',
  EmployeeXRefCode: 'employeeXRefCode',
  EmployeePayAdjustXRefCode: 'employeePayAdjustXRefCode',
  PayAdjustmentCodeXRefCode: 'payAdjustmentCodeXRefCode',
  OrgUnitXRefCode: 'orgUnitXRefCode',
  PayAdjustmentDate: 'payAdjustmentDate',
};
export const COLUMNS_KEY_LABEL_TASK = {
  [COLUMNS_KEY_TASK.Act]: 'Act',
  [COLUMNS_KEY_TASK.Plant]: 'Plant',
  [COLUMNS_KEY_TASK.RecCCtrl]: 'Rec CCtrl',
  [COLUMNS_KEY_TASK.RecOrder]: 'Rec Order',
  [COLUMNS_KEY_TASK.SendCostCenter]: 'Send Cost Center',
  [COLUMNS_KEY_TASK.ActType]: 'Act Type',
  [COLUMNS_KEY_TASK.AAType]: 'AAType',
  [COLUMNS_KEY_TASK.TocsID]: 'TOCS ID',
  [COLUMNS_KEY_TASK.Network]: 'Network',
  [COLUMNS_KEY_TASK.WbsElement]: 'Wbs Element',
  [COLUMNS_KEY_TASK.WorkCenter]: 'WorkCenter',
  [COLUMNS_KEY_TASK.EmployeeXRefCode]: 'Employee XRefCode',
  [COLUMNS_KEY_TASK.EmployeePayAdjustXRefCode]: 'Employee PayAdjust XRefCode',
  [COLUMNS_KEY_TASK.OrgUnitXRefCode]: 'OrgUnit XRefCode',
  [COLUMNS_KEY_TASK.PayAdjustmentDate]: 'Pay Adjustment Date',
  [COLUMNS_KEY_TASK.Hours]: 'Hours',
};
export const COLUMNS_KEY_DATE_OF_WEEK = {
  Fri: 'FRI',
  Sat: 'SAT',
  Sun: 'SUN',
  Mon: 'MON',
  Tue: 'TUE',
  Wed: 'WED',
  Thu: 'THU',
};
export const DATE_OF_WEEK_NUMBER_MAPPER = [
  [COLUMNS_KEY_DATE_OF_WEEK.Fri, 5],
  [COLUMNS_KEY_DATE_OF_WEEK.Sat, 6],
  [COLUMNS_KEY_DATE_OF_WEEK.Sun, 0],
  [COLUMNS_KEY_DATE_OF_WEEK.Mon, 1],
  [COLUMNS_KEY_DATE_OF_WEEK.Tue, 2],
  [COLUMNS_KEY_DATE_OF_WEEK.Wed, 3],
  [COLUMNS_KEY_DATE_OF_WEEK.Thu, 4],
];
export const TABLE_LIST_COLUMN_TASK = [
  {
    key: COLUMNS_KEY_TASK.SendCostCenter,
    label: COLUMNS_KEY_LABEL_TASK[COLUMNS_KEY_TASK.SendCostCenter],
  },
  {
    key: COLUMNS_KEY_TASK.ActType,
    label: COLUMNS_KEY_LABEL_TASK[COLUMNS_KEY_TASK.ActType],
  },
  {
    key: COLUMNS_KEY_TASK.RecCCtrl,
    label: COLUMNS_KEY_LABEL_TASK[COLUMNS_KEY_TASK.RecCCtrl],
  },
  {
    key: COLUMNS_KEY_TASK.WbsElement,
    label: COLUMNS_KEY_LABEL_TASK[COLUMNS_KEY_TASK.WbsElement],
  },
  {
    key: COLUMNS_KEY_TASK.RecOrder,
    label: COLUMNS_KEY_LABEL_TASK[COLUMNS_KEY_TASK.RecOrder],
  },
  {
    key: COLUMNS_KEY_TASK.Network,
    label: COLUMNS_KEY_LABEL_TASK[COLUMNS_KEY_TASK.Network],
  },
  {
    key: COLUMNS_KEY_TASK.Act,
    label: COLUMNS_KEY_LABEL_TASK[COLUMNS_KEY_TASK.Act],
    isDisableInput: true, // Field ACT giờ mình sẽ không gửi qua Dayforce nữa. Mình lock field đó lại không cho input và hard code value ACT10 luôn
  },
  {
    key: COLUMNS_KEY_TASK.AAType,
    label: COLUMNS_KEY_LABEL_TASK[COLUMNS_KEY_TASK.AAType],
    isDisableInput: true,
  },
  {
    key: COLUMNS_KEY_TASK.TocsID,
    label: COLUMNS_KEY_LABEL_TASK[COLUMNS_KEY_TASK.TocsID],
  },
];
export const getSettingColumnDisplay = () => {
  return TABLE_LIST_COLUMN_TASK.map((k) => {
    return {
      columnKey: k.key,
      isShow: true,
    };
  });
};
