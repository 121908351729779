/* eslint-disable */
import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_FACEBOOK_USER,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_GITHUB_USER,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_TWITTER_USER,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNIN_SSO_AZURE_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  SIGNIN_USER_LOAD_PROFILE_ME,
} from '../../constants/ActionTypes';
import { SIGNIN_USER_ERROR } from '../../constants/ActionTypes';
import { TOKEN_FAKE, USER_FAKE } from '../../sampleData';
import axios from '../../util/Api';
// import { API_URL } from '../../app.const';
import { loginDataSample } from '../../sampleData/loginDataSample';
import { API_URL, LOGIN_TYPE_AZURE, LOGIN_TYPE_PASSWORD } from '../../app.const';

// export const userSignUp = (user) => {
//   return {
//     type: SIGNUP_USER,
//     payload: user,
//   };
// };
// export const userSignIn = (user) => {
//   console.log(`saga userSignIn`, user);
//   return {
//     type: SIGNIN_USER,
//     payload: user,
//   };
// };
export const userSignInAzureSuccess = ({ token, user, loginType, idToken, accessToken }) => {
  return {
    type: SIGNIN_SSO_AZURE_SUCCESS,
    payload: {
      user: user,
      token: token,
      loginType: loginType || LOGIN_TYPE_AZURE,
      idToken,
      accessToken,
    },
  };
};

export const loadUserProfileMeApi = async (params = null) => {
  const { accessToken, idToken } = params || {};
  try {
    let result = await axios.get(API_URL + '/profile/me', {
      headers: {
        authorization: idToken,
        'x-access-token': accessToken,
      },
    });
    result = result && result.data ? result.data : result;
    console.log('[loadUserProfileMeApi success]  result=', result);
    if (!result) {
      throw `Login fail. User does not have setting employeeId in MS Profile`;
    }
    return result;
  } catch (error) {
    console.error('[loadUserProfileMeApi] error ', error);
    throw error;
  }
};

export const signInWithEmailPasswordThunk = ({ username, password, cb }) => {
  return async (dispatch, getState) => {
    // console.log(`reduxthunk: signInWithEmailPassword {username, password, cb} = `, { username, password, cb, oldState: getState() });
    dispatch({
      type: ON_SHOW_LOADER,
    });
    try {
      let result = await axios.post(API_URL + '/login', {
        username,
        password,
      });
      if (!result || !result.data) {
        throw new Error('User is not found');
      }
      console.log('[signInWithEmailPasswordThunk success] login result', result.data);
      if (cb) cb(null, result.data);
      return dispatch({
        type: SIGNIN_USER_SUCCESS,
        payload: {
          token: result.data.token,
          user: result.data,
          loginType: LOGIN_TYPE_PASSWORD,
        },
      });
    } catch (error) {
      console.error('[signInWithEmailPasswordThunk] error ', error);

      if (cb) cb(error, null);
      return dispatch({
        type: SIGNIN_USER_ERROR,
        payload: error,
      });
    }
  };
};
export const userSignOutThunkAction = () => {
  console.log('userSignOutThunkAction', SIGNOUT_USER);
  return async (dispatch, getState) => {
    console.log(`reduxthunk: userSignOutThunkAction = `);
    dispatch({
      type: ON_SHOW_LOADER,
    });
    try {
      // let result = await axios.post(API_URL + '/api/auth/login');
      // if(cb) cb(null, result);
      return dispatch({
        type: SIGNOUT_USER_SUCCESS,
        payload: {
          token: null,
          user: null,
          loginType: '',
        },
      });
    } catch (error) {
      console.log(error);
      return dispatch({
        type: SIGNOUT_USER,
        payload: {
          token: null,
          user: null,
          loginType: '',
        },
      });
    }
  };
};

export const userSignOut = () => {
  console.log('click sign out', SIGNOUT_USER);
  return {
    type: SIGNOUT_USER,
  };
};

// export const userSignUpSuccess = (authUser) => {
//   return {
//     type: SIGNUP_USER_SUCCESS,
//     payload: authUser,
//   };
// };

// export const userSignInSuccess = (authUser) => {
//   return {
//     type: SIGNIN_USER_SUCCESS,
//     payload: {
//       user: authUser,
//       token: authUser.token || '',
//     },
//   };
// };
// export const userSignOutSuccess = () => {
//   return {
//     type: SIGNOUT_USER_SUCCESS,
//   };
// };

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
  };
};

export const userGoogleSignIn = () => {
  return {
    type: SIGNIN_GOOGLE_USER,
  };
};
export const userGoogleSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_GOOGLE_USER_SUCCESS,
    payload: authUser,
  };
};
export const userFacebookSignIn = () => {
  return {
    type: SIGNIN_FACEBOOK_USER,
  };
};
export const userFacebookSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_FACEBOOK_USER_SUCCESS,
    payload: authUser,
  };
};
export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};
export const userTwitterSignIn = () => {
  return {
    type: SIGNIN_TWITTER_USER,
  };
};
export const userTwitterSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_TWITTER_USER_SUCCESS,
    payload: authUser,
  };
};
export const userGithubSignIn = () => {
  return {
    type: SIGNIN_GITHUB_USER,
  };
};
export const userGithubSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_GITHUB_USER_SUCCESS,
    payload: authUser,
  };
};
export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};
