/* eslint-disable */
import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNIN_USER_SUCCESS,
  SIGNIN_SSO_AZURE_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER_SUCCESS,
} from 'constants/ActionTypes';
import { SIGNIN_USER_ERROR, SIGNIN_USER_LOAD_PROFILE_ME, SIGNOUT_USER } from '../../constants/ActionTypes';
import { clearLocalStorage, getLoginType, getTokenAuthoried, getUserAuthoried, setLoginType, setUserAuthoried } from '../../util/helper';
import { ACCESS_TOKEN_KEY, ID_TOKEN_KEY, LOGIN_TYPE_PASSWORD } from '../../app.const';

const INIT_STATE = {
  loader: false,
  alertMessage: '',
  showMessage: false,
  initURL: '',
  authUser: getUserAuthoried() || null,
  token: getTokenAuthoried() || null,
  ssoToken: {
    idToken: getTokenAuthoried(ID_TOKEN_KEY) || null,
    accessToken: getTokenAuthoried(ACCESS_TOKEN_KEY) || null,
  },
  loginType: getLoginType() || LOGIN_TYPE_PASSWORD, // 'password'
};

export default (state = INIT_STATE, action) => {
  const { token, user, idToken, accessToken } = action.payload || {};
  let loginType = action.payload && action.payload.loginType;
  if (!loginType) loginType = state.loginType || LOGIN_TYPE_PASSWORD;

  switch (action.type) {
    case SIGNIN_USER_LOAD_PROFILE_ME: {
      setUserAuthoried({}, { ...(user || state.user) });
      return {
        ...state,
        loader: false,
        authUser: {
          ...(user || state.user),
        },
        // token: accessToken,
        // ssoToken: {
        //   idToken: idToken,
        //   accessToken: accessToken,
        // },
        // loginType,
      };
    }
    case SIGNIN_SSO_AZURE_SUCCESS: {
      setUserAuthoried({ token, idToken, accessToken }, { ...user, token: '' });
      setLoginType(loginType);
      return {
        ...state,
        loader: false,
        authUser: { ...user, token: '' },
        token: accessToken,
        ssoToken: {
          idToken: idToken,
          accessToken: accessToken,
        },
        loginType,
      };
    }
    case SIGNIN_USER_SUCCESS: {
      // console.log(`sign in success`, state, action, {token, user});
      setUserAuthoried({ token }, { ...user, token: '' });
      setLoginType(loginType);
      return {
        ...state,
        loader: false,
        authUser: { ...user, token: '' },
        token: token,
        loginType: LOGIN_TYPE_PASSWORD,
      };
    }
    case SIGNIN_USER_ERROR: {
      console.log(`sign in error`, state, action);
      clearLocalStorage();
      setLoginType(null);
      return {
        ...state,
        loader: false,
        alertMessage: 'Username or password is invalid',
        showMessage: true,
        authUser: null,
        token: null,
      };
    }

    //Signout
    //Signout
    //Signout
    case SIGNOUT_USER:
    case SIGNOUT_USER_SUCCESS: {
      // console.log("SIGNOUT_USER_SUCCESS");
      clearLocalStorage();
      return {
        ...state,
        authUser: null,
        token: null,
        initURL: '/',
        loader: false,
        loginType,
      };
    }

    case SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }

    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload,
      };
    }

    //loading & error
    //loading & error
    //loading & error
    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
      };
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false,
      };
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true,
      };
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false,
      };
    }
    default:
      return state;
  }
};
