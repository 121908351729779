/* eslint-disable */
import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_FACEBOOK_USER,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_GITHUB_USER,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_TWITTER_USER,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
} from 'constants/ActionTypes';
import { v4 } from 'uuid';
import { FETCH_LIST_TASKS_ERROR, FETCH_LIST_TASKS_START, FETCH_LIST_TASKS_SUCCESS, SIGNIN_USER_ERROR } from '../../constants/ActionTypes';
import { TOKEN_FAKE, USER_FAKE } from '../../sampleData';
import axios from '../../util/Api';
// import { API_URL } from '../../app.const';
import { loginDataSample } from '../../sampleData/loginDataSample';
import _ from 'lodash';
import { API_URL } from '../../app.const';
export const listTasksReduxAction = (params = {}) => {
  const cb = params.cb;
  return async (dispatch, getState) => {
    // console.log(`reduxthunk: signInWithEmailPassword {username, password, cb} = `, { username, password, cb, oldState: getState() });
    dispatch({
      type: FETCH_LIST_TASKS_START,
    });
    let url = API_URL + '/tasks';
    try {
      let result = await axios.get(url, {
        params: _.pick(params, ['fromDate', 'toDate', 'userId', 'statusCode', 'url', 'method', 'limit', 'offset']),
      });
      console.log('[listTasksAction success] result', result.data);
      if (!result || !result.data) {
        // throw new Error(`Call api ${url} fail with empty data`);
        if (cb) cb(new Error(`Call api ${url} fail with empty data`), null);
      }
      if (cb) cb(null, result.data);
      return dispatch({
        type: FETCH_LIST_TASKS_SUCCESS,
        payload: { tasks: result.data, error: null },
      });
    } catch (error) {
      console.error('[listTasksAction] error ', error);
      if (cb) cb(error, null);
      return dispatch({
        type: FETCH_LIST_TASKS_ERROR,
        payload: { error },
      });
    }
  };
};
export const listTasksPromise = async (params = {}) => {
  let url = API_URL + '/tasks';
  try {
    let result = await axios.get(url, {
      params: _.pick(params, ['fromDate', 'toDate', 'userId', 'statusCode', 'url', 'method', 'limit', 'offset']),
    });
    // console.log("[listTasksPromise success] result", result);
    if (!result || !result.data) {
      return [];
    }
    return result.data.map((r) => {
      r.id = r.employeePayAdjustXRefCode || r.id || v4();
      return r;
    });
  } catch (error) {
    console.error('[listTasksPromise] error ', error);
    throw error;
  }
};
export const validateTaskPromise = async (params) => {
  try {
    let result = await axios.post(API_URL + `/tasks/validate`, {
      ...params,
    });
    console.log('[validateTaskPromise success] result', result.data);
    return result.data;
  } catch (error) {
    console.log('[validateTaskPromise error]', error);
    throw error;
  }
};
export const updateTaskPromise = async (id, params) => {
  try {
    let result = await axios.put(API_URL + `/tasks/${id}`, {
      ...params,
    });
    console.log('[updateTaskPromise success] result', result.data);
    return result.data;
  } catch (error) {
    console.log('[updateTaskPromise error]', error);
    throw error;
  }
};

export const createTaskPromise = async (params) => {
  try {
    let result = await axios.post(API_URL + `/tasks`, {
      ...params,
    });
    console.log('[createTaskPromise success] result', result.data);
    return result.data;
  } catch (error) {
    console.log('[createTaskPromise error]', error);
    throw error;
  }
};

export const deleteTaskPromise = async (id) => {
  try {
    let result = await axios.delete(API_URL + `/tasks/${id}`, {});
    console.log('[deleteTaskPromise success] result', result.data);
    return result.data;
  } catch (error) {
    console.log('[deleteTaskPromise error]', error);
    throw error;
  }
};
