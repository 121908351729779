/* eslint-disable */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Popover, message } from 'antd';
import { userSignOut } from '../../appRedux/actions/Auth';
import { getDisplayUsername } from '../../util/helper';
import { useMsal } from '@azure/msal-react';
import { LOGIN_TYPE_AZURE } from '../../app.const';

const UserInfo = (props = {}) => {
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const { authUser, initURL, loginType } = useSelector(({ auth }) => auth);
  const [position, setposition] = useState(null);
  const handleLogout = async () => {
    try {
      if (loginType == LOGIN_TYPE_AZURE) {
        await instance.logoutPopup(); // Call the logoutPopup method from the instance object to initiate the sign-out process.
      }
      dispatch(userSignOut());
    } catch (error) {
      console.error(error);
      message.error(error && error.message ? JSON.stringify(error.message) : JSON.stringify(error));
    }
  };
  const userMenuOptions = (
    <ul className="gx-user-popover top-menu-profile">
      <li className="logout" onClick={() => handleLogout()}>
        Logout
      </li>
    </ul>
  );
  useEffect(() => {
    if (props && props.position) {
      setposition(props.position);
    }
  }, [props.position]);

  // let position = props && props.position;
  let displayName = getDisplayUsername(authUser);
  if (!position) return null;

  if (position != undefined && 'topNavBar' == position && userMenuOptions) {
    return (
      <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions} trigger="click">
        <div className="d-flex pointer">
          <span className="user-info-top-nav-bar pointer">
            {displayName}
            <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2"></i>
          </span>
          <Avatar src={'https://via.placeholder.com/150'} className="gx-avatar gx-pointer" alt="" />
        </div>
      </Popover>
    );
  }
  return (
    <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions} trigger="click">
      <span>{displayName}</span>
      <Avatar src={'https://via.placeholder.com/150'} className="gx-avatar gx-pointer" alt="" />
    </Popover>
  );
};

export default UserInfo;
