/* eslint-disable */
import React from 'react';
import { Col, Row, DatePicker, Space, Modal, Menu, Dropdown, Icon, message, Tooltip as AntdToolTip, Select } from 'antd';
import ReactJson from 'react-json-view';

import { Area, AreaChart, Line, LineChart, ResponsiveContainer, Tooltip } from 'recharts';
import ChartCard from 'components/dashboard/Crypto/ChartCard';
import Auxiliary from 'util/Auxiliary';
import Portfolio from 'components/dashboard/Crypto/Portfolio';
import BalanceHistory from 'components/dashboard/Crypto/BalanceHistory';
import SendMoney from 'components/dashboard/Crypto/SendMoney';
import RewardCard from 'components/dashboard/Crypto/RewardCard';
import CurrencyCalculator from 'components/dashboard/Crypto/CurrencyCalculator';
import CryptoNews from 'components/dashboard/Crypto/CryptoNews';
import DownloadMobileApps from 'components/dashboard/Crypto/DownloadMobileApps';
import OrderHistory from 'components/dashboard/Crypto/OrderHistory';
import { increamentData, lineData } from '../routes/main/Metrics/data';
import * as _ from 'lodash';
import * as uuid from 'uuid';
import { createLogPromise, deleteLogPromise, listLogPromise, updateLogPromise } from '../appRedux/actions/Log';
import BlockUi from 'react-block-ui';
import { COLUMNS_KEY_TASK, COLUMNS_KEY_DATE_OF_WEEK } from '../app.const';
import {
  formatDetailsErrorArrayFromApi,
  openNotification,
  parseJson,
  // removeFieldNotAllowUpdateLog,
  // removeFieldsNotAllowAddLog,
  // removeFieldsNotAllowCreate,
  // removeFieldsNotAllowUpdate,
} from '../util/helper';
import moment from 'moment';
import { listUserPromise } from '../appRedux/actions/User';
const { RangePicker } = DatePicker;
const ACTION_CREATE = 'ACTION_CREATE';
const ACTION_UPDATE = 'ACTION_UPDATE';
const ACTION_DELETE = 'ACTION_DELETE';
const KEYS_CHECK_PAYLOAD_BEFORE_SUBMIT = ['act', 'plant', 'recCCtr', 'recOrder', 'sendCostCenter'];
const LOG_COLUMNS = [
  {
    key: 'id',
    type: 'html',
    render: (columnData, { click }) => {
      if (!columnData) return '';
      return (
        <>
          <span>{columnData}</span>

          <button className="btn btn-primary ml-2 " onClick={(e) => click && click()}>
            <i className="fa fa-eye pointer"></i>
            View{' '}
          </button>
        </>
      );
    },
  },
  {
    key: 'createdAt',
    type: 'text',
    mergeColumn: ['createdAt'],
    render: (columnData, opt = null) => {
      if (!columnData) return '';
      return moment(columnData).format('HH:mm:ss DD/MM/YY');
    },
  },
  {
    key: 'statusCode',
    type: 'text',
  },
  {
    key: 'method',
    type: 'text',
  },
  {
    key: 'url',
    type: 'html',
    // mergeColumn: ['url'],
    // style:{
    //   width: '100px'
    // },
    className: 'log-url',
    render: (columnData, { click }) => {
      if (!columnData) return '';
      let url = columnData;
      // console.log(columnData);
      let urlArr = String(url).split('?');
      let query = urlArr[1];
      let value = String(url)
        .replace(/.*\.com/, '')
        .toString();
      return (
        <AntdToolTip title={url}>
          <span>{value}</span>
          {/* <span className="text-primary pointer" onClick={e=>{
          click && click();
        }}> more detail</span> */}
        </AntdToolTip>
      );
    },
  },
  // {
  //   key: 'payload',
  //   type: 'json',
  //   render: (columnData)=>{
  //     if(!columnData) return '';
  //     return <ReactJson collapsed={1} src={parseJson(columnData)} displayDataTypes={false} name={false} />;
  //   }
  // },
  // {
  //   key: 'query',
  //   type: 'text',
  // },

  // {
  //   key: 'response',
  //   type: 'json',
  //   render: (columnData)=>{
  //     if(!columnData) return '';
  //     let newJson = parseJson(columnData);
  //     if(newJson && newJson.access_token){
  //       newJson.access_token = '...';
  //     }
  //     return <ReactJson collapsed={1} src={newJson} displayDataTypes={false} name={false} />;
  //   }
  // },
  {
    key: 'error',
    type: 'text',
    className: 'log-error',
    render: (columnData, { click }) => {
      if (!columnData) return '';

      return (
        <AntdToolTip title={columnData}>
          <span>{columnData}</span>
          {/* <i className="fa fa-eye pointer" onClick={e=> click && click()}></i> */}
        </AntdToolTip>
      );
    },
  },
  {
    key: 'userId',
    type: 'text',
  },

  // {
  //   key: 'updatedAt',
  //   type: 'text',
  // },
];
export default class LogPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpandRight: false,
      listLog: [],

      blockUi: false,
      showRightBlock: false,
      columnDisplay: LOG_COLUMNS.map((k) => {
        return {
          columnKey: k.key,
          isShow: true,
        };
      }),
      tmpColumnDisplay: [],
      action: '',
      fromDate: moment().add(-7, 'days').format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      searchCondition: {
        statusCode: '',
        userId: '',
        url: '',
        method: '',
        query: '',
        payload: '',
        response: '',
        displayName: '',
      },
      showModalLogDetail: false,
      currentLog: null,
      users: [],
    };
  }
  setStateAsync(stateUpdate) {
    return new Promise((resolve) => {
      this.setState(stateUpdate, () => resolve());
    });
  }
  startBlockUi = () => {
    this.setState({
      blockUi: true,
    });
  };
  stopBlockUi = () => {
    this.setState({
      blockUi: false,
    });
  };
  componentDidMount = async () => {
    this.startBlockUi();
    await this.getLogData();
    await this.getListUserPromise();
    this.stopBlockUi();
  };
  getLogData = async () => {
    let searchCondition = this.state.searchCondition;
    let { statusCode, userId, url, method, query, payload, response, displayName } = searchCondition;
    try {
      let params = {
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
      };
      if (statusCode) params['statusCode'] = statusCode;
      if (userId) params['userId'] = userId;
      if (url) params['url'] = url;
      if (method) params['method'] = method;
      if (query) params['query'] = query;
      if (payload) params['payload'] = payload;
      if (response) params['response'] = response;
      console.log(`search log with params `, params);

      let res = await listLogPromise({
        ...params,
      });
      // console.log("get logs ", res);
      this.setState({
        listLog: res,
      });
    } catch (error) {
      console.log(error);
      message.error(error.message || JSON.stringify(error));
    }
  };
  getListUserPromise = async () => {
    let searchCondition = this.state.searchCondition;
    let { displayName, xRefCode } = searchCondition;
    try {
      let params = {};
      if (displayName) params['displayName'] = displayName;
      if (xRefCode) params['xRefCode'] = xRefCode;
      let res = await listUserPromise({
        ...params,
      });
      console.log('getListUserPromise users ', res);
      this.setState({
        users: res,
      });
    } catch (error) {
      console.log(error);
      message.error(error.message || JSON.stringify(error));
    }
  };
  isShowColumn = (columnKey) => {
    let { columnDisplay } = this.state;
    let find = _.find(columnDisplay, { columnKey, isShow: true });
    return !!find;
  };
  isShowColumnDateOfWeek = (columnKey) => {
    return true;
  };
  submitDeleteLog = async (log, idx) => {
    Modal.confirm({
      title: 'Confirm !',
      content: 'Do you want to delete this log ?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: async () => {
        this.startBlockUi();
        try {
          let id = encodeURIComponent(log.id);
          let res = await deleteLogPromise(id);
          message.success('Delete log successfully');
        } catch (error) {
          console.log(error);
          message.error(error.message);
        }
        await this.getLogData();
        this.stopBlockUi();
      },
      onCancel: () => {},
    });
  };
  // onActionUpsertLog = (action, log, idx) => {
  //   if (action == ACTION_UPDATE) {
  //     this.setState({
  //       action: ACTION_UPDATE,
  //       currentLog: _.cloneDeep(log),
  //     });
  //   } else if (action == ACTION_CREATE) {
  //     this.setState({
  //       action: ACTION_CREATE,
  //       currentLog: _.cloneDeep(log),
  //     });
  //   }
  // };
  onChangeInputCurrentLog = async (e, aLog, idx, key) => {
    let currentLog = this.state.currentLog;
    currentLog[key] = e.target.value;
    this.setState({
      currentLog,
    });
  };
  closeAllModal = async () => {
    Modal.destroyAll();
    await this.setStateAsync({
      action: '',
      // showModalDisplayColumn: false,
      // showModalAddToLog: false,
      // showModalDeleteTask: false,
      // showModalScanSignal: false,
      // showModalUseLog: false,
      currentLog: null,
    });
  };
  checkIsHaveChangeData = (editedObject, objB) => {
    console.log('editedObject', editedObject);
    let isNew = editedObject.isNew;
    let haveChange = false;
    let keys = Object.keys(editedObject);
    for (let k of keys) {
      if (k == 'isNew' || k == 'id') continue;
      if (typeof editedObject[k] != 'function') {
        if (editedObject[k] !== objB[k] || (isNew && editedObject[k])) {
          haveChange = true;
        }
      }
    }
    return haveChange;
  };
  showNotificationValidateLog = (error) => {
    let errorsArr = formatDetailsErrorArrayFromApi(error);
    openNotification({
      type: 'error',
      title: 'Validate fail',
      description: (
        <div>
          {errorsArr.map((r, idx) => {
            return (
              <span className="d-block" key={idx}>
                {r.message}
              </span>
            );
          })}
        </div>
      ),
    });
  };
  setRowError = async (error) => {
    // neu pass vao array thi su dung luon
    let errorsArr = Array.isArray(error) && error.length > 0 ? error : formatDetailsErrorArrayFromApi(error);
    let currentLog = this.state.currentLog;
    let listLog = this.state.listLog;
    listLog = listLog.map((r) => {
      delete r.isError;
      delete r.errors;
      return r;
    });
    let findIdx = _.findIndex(listLog, { id: currentLog.id });
    console.log('find idx', findIdx);
    if (findIdx >= 0) {
      listLog[findIdx].isError = true;
      listLog[findIdx].errors = errorsArr;
      await this.setStateAsync({
        listLog,
      });
    }
  };
  checkPayloadBeforeSubmit = (payload) => {
    let keys = KEYS_CHECK_PAYLOAD_BEFORE_SUBMIT;
    let errorsArr = [];
    for (let k of keys) {
      if (!payload[k]) {
        errorsArr.push({
          key: k,
          message: `Value of "${k}" is invalid`,
        });
      }
    }
    if (errorsArr.length > 0) {
      openNotification({
        type: 'error',
        title: 'Validate fail',
        description: (
          <div>
            {errorsArr.map((r, idx) => {
              return (
                <span className="d-block" key={idx}>
                  {r.message}
                </span>
              );
            })}
          </div>
        ),
      });
      // this.setRowError(errorsArr);
      return {
        success: false,
        errors: errorsArr,
      };
    }
    return {
      success: true,
      errors: null,
    };
  };
  submitSaveEditOnOneRow = async () => {
    let currentLog = this.state.currentLog;
    let listLog = this.state.listLog;
    let isSubmitNew = currentLog.isNew;
    Modal.confirm({
      title: isSubmitNew ? 'Create new log' : 'Update log',
      content: isSubmitNew ? 'Do you want to create new log' : 'Do you want to update this log',
      onOk: async (e) => {
        let paramCreateUpdate = {
          ...currentLog,
        };
        console.log('paramCreateUpdate ', paramCreateUpdate);
        if (isSubmitNew) {
          let paramCreate = removeFieldsNotAllowAddLog(paramCreateUpdate);
          let rsCheckPayload = this.checkPayloadBeforeSubmit(paramCreate);
          if (rsCheckPayload.errors) {
            this.setRowError(rsCheckPayload.errors); // set row error
          }
          if (rsCheckPayload.success == true) {
            try {
              await createLogPromise({ logs: [paramCreate] });
              await this.getLogData();
              message.success('Create successfully');
              this.closeAllModal();
            } catch (error) {
              console.log(error);
              // message.error(error.message || JSON.stringify(error));
              this.showNotificationValidateLog(error);
              this.setRowError(error);
            }
          }
        } else {
          let paramUpdate = removeFieldNotAllowUpdateLog(paramCreateUpdate);
          console.log('param update ', paramUpdate);
          let rsCheckPayload = this.checkPayloadBeforeSubmit(paramUpdate);
          if (rsCheckPayload.errors) {
            this.setRowError(rsCheckPayload.errors); // set row error
          }
          if (rsCheckPayload.success == true) {
            try {
              await updateLogPromise(encodeURIComponent(paramCreateUpdate.id), paramUpdate);
              await this.getLogData();
              message.success('Update successfully');
              this.closeAllModal();
            } catch (error) {
              console.log(error);
              // message.error(error.message || JSON.stringify(error));
              this.showNotificationValidateLog(error);
              this.setRowError(error);
            }
          }
        }
      },
      onCancel: () => {
        // this.closeAllModal();
        Modal.destroyAll();
      },
    });
  };
  closeEditOnOneRow = async (log, idx) => {
    let currentLog = this.state.currentLog;
    let listLog = this.state.listLog;
    let isNew = currentLog.isNew;
    let haveChange = this.checkIsHaveChangeData(currentLog, listLog[idx]);
    if (!haveChange) {
      if (currentLog.isNew) {
        listLog.splice(idx, 1);
        await this.setStateAsync({
          listLog,
          currentLog: null,
          action: '',
        });
      } else {
        // cancel update => reset origin data row
        await this.setStateAsync({
          currentLog: null,
          action: '',
        });
      }
      this.closeAllModal();
      return;
    }
    Modal.confirm({
      title: isNew ? 'Do you want to remove temporary log ?' : 'Do you want to discard change for this log ?',
      onOk: async (e) => {
        // new log => remove row
        if (currentLog.isNew) {
          listLog.splice(idx, 1);
          await this.setStateAsync({
            listLog,
            currentLog: null,
            action: '',
          });
        } else {
          // cancel update => reset origin data row
          await this.setStateAsync({
            currentLog: null,
            action: '',
          });
        }
        this.closeAllModal();
      },
      onCancel: (e) => {
        // this.closeAllModal();
        Modal.destroyAll();
      },
    });
  };
  showModalMoreDetail = (log) => {
    this.setState({
      showModalLogDetail: true,
      currentLog: { ...log },
    });
  };
  onChangeSearchParam = async (key, e) => {
    let { isExpandRight, showRightBlock, listLog, action, currentLog, showModalLogDetail, searchCondition } = this.state;
    searchCondition[key] = typeof e == 'string' ? e : e.target.value;
    await this.setStateAsync({
      searchCondition,
    });
  };

  getDisplayNameForSelect = (userId) => {
    if (!userId) return null;
    let users = this.state.users || [];
    let find = _.find(users, { id: parseInt(userId) });
    if (find) return find.displayName;
    return null;
  };

  render() {
    let { isExpandRight, showRightBlock, listLog, action, currentLog, showModalLogDetail, searchCondition, users } = this.state;
    let rightCol = !isExpandRight ? 0 : 8;
    let leftCol = !isExpandRight ? 24 : 16;
    const isEditMode = !!currentLog && [ACTION_CREATE, ACTION_UPDATE].includes(action);

    return (
      <Auxiliary>
        <BlockUi blocking={this.state.blockUi}>
          <div className="log-page  ant-card">
            <div className=" ant-card-body">
              <div className="timesheet">
                <Row>
                  <Col xl={leftCol} lg={leftCol} md={leftCol} sm={leftCol} xs={leftCol}>
                    {/* <label className="text-primary label-text-log mr-2">Log</label> */}
                    <span className="w-100 mr-2 mb-2">
                      <RangePicker
                        onChange={async (dates, dateStrings) => {
                          console.log(dates, dateStrings);
                          if (Array.isArray(dateStrings) && dateStrings[0] && dateStrings[1]) {
                            await this.setStateAsync({
                              fromDate: dateStrings[0],
                              toDate: dateStrings[1],
                            });
                            // setTimeout(async () => {
                            //   this.startBlockUi();
                            //   await this.getLogData();
                            //   this.stopBlockUi();
                            // }, 1000);
                          }
                        }}
                      />
                      {/* <DatePicker
                      onChange={(date, dateString) => {
                        console.log(date, dateString);
                      }}
                      // picker="week"
                      className="mr-2"
                      format={"YYYY-MM-DD"}
                      style={{ verticalAlign: "super" }}
                    /> */}
                      <span className="height-30px mr-2 mb-2" style={{ width: '90px', display: 'inline-block', verticalAlign: 'top' }}>
                        <input
                          className="form-control app-form-control"
                          value={searchCondition.statusCode}
                          placeholder="statusCode"
                          onChange={(e) => {
                            this.onChangeSearchParam('statusCode', e);
                          }}
                        />
                      </span>

                      <span className="height-30px mr-2 mb-2" style={{ width: '70px', display: 'inline-block', verticalAlign: 'top' }}>
                        <input
                          className="form-control app-form-control"
                          value={searchCondition.method}
                          placeholder="method"
                          onChange={(e) => {
                            this.onChangeSearchParam('method', e);
                          }}
                        />
                      </span>

                      <span className="height-30px mr-2 mb-2" style={{ width: '200px', display: 'inline-block', verticalAlign: 'top' }}>
                        <Select
                          className="select-app-form-control"
                          showSearch
                          allowClear
                          value={this.getDisplayNameForSelect(searchCondition.userId)}
                          placeholder={'Search by displayName'}
                          // style={props.style}
                          defaultActiveFirstOption={false}
                          suffixIcon={null}
                          // filterOption={false}
                          filterOption={(input, option) => (option?.label ?? '').includes(input)}
                          onSearch={(newValue) => {
                            // fetch(newValue, setData);
                          }}
                          onChange={(e) => {
                            console.log('Select userId =', e);
                            if (e) {
                              this.onChangeSearchParam('userId', String(e).toString());
                            } else {
                              this.onChangeSearchParam('userId', '');
                            }
                          }}
                          notFoundContent={null}
                          options={(this.state.users || []).map((d) => ({
                            value: d.id,
                            label: d.displayName,
                          }))}
                        />
                      </span>

                      <span className="height-30px mr-2 mb-2" style={{ width: '250px', display: 'inline-block', verticalAlign: 'top' }}>
                        <input
                          className="form-control app-form-control"
                          value={searchCondition.url}
                          placeholder="url"
                          onChange={(e) => {
                            this.onChangeSearchParam('url', e);
                          }}
                        />
                      </span>
                      <span className="height-30px mr-2 mb-2" style={{ width: '200px', display: 'inline-block', verticalAlign: 'top' }}>
                        <input
                          className="form-control app-form-control"
                          value={searchCondition.query}
                          placeholder="query"
                          onChange={(e) => {
                            this.onChangeSearchParam('query', e);
                          }}
                        />
                      </span>
                      <span className="height-30px mr-2 mb-2" style={{ width: '200px', display: 'inline-block', verticalAlign: 'top' }}>
                        <input
                          className="form-control app-form-control"
                          value={searchCondition.payload}
                          placeholder="payload"
                          onChange={(e) => {
                            this.onChangeSearchParam('payload', e);
                          }}
                        />
                      </span>

                      <span className="mr-2 mb-2">
                        <button
                          className="btn btn-primary app-button"
                          onClick={async (e) => {
                            this.startBlockUi();
                            await this.getLogData();
                            this.stopBlockUi();
                          }}
                        >
                          <i className="fa fa-search fa-refresh"></i>
                        </button>
                      </span>
                    </span>

                    {/* <span className="pull-right">
                      <button
                        className="btn btn-primary app-button"
                        onClick={(e) => {
                          this.props.history.push("main");
                        }}
                      >
                        Manage Task
                      </button>
                    </span> */}
                  </Col>
                </Row>
                <Row>
                  <Col className="timesheet-left" style={{ transition: 'all 0.3s ease', position: 'relative' }} xl={leftCol} lg={leftCol} md={leftCol} sm={leftCol} xs={leftCol}></Col>
                  <Col className="timesheet-right" style={{ transition: 'all 0.3s ease', transitionDelay: '0.2s' }} xl={rightCol} lg={rightCol} md={rightCol} sm={rightCol} xs={rightCol}></Col>
                </Row>
                <Row style={{ overflowX: 'hidden' }}>
                  <Col style={{ transition: 'all 0.3s ease', position: 'relative' }} xl={leftCol} lg={leftCol} md={leftCol} sm={leftCol} xs={leftCol}>
                    {/* <i
                    className={` icon-arrow-expand icon icon-arrow-${isExpandRight ? "left" : "right"} pointer`}
                    onClick={(e) => {
                      this.toggeExpanRight();
                    }}
                  ></i> */}
                    <div className="table-responsive">
                      <table className={`table table-striped table-hover table-log-left ${isEditMode ? ' open-edit-mode ' : ''}`}>
                        <thead className="bg-info">
                          <tr>
                            {LOG_COLUMNS.map((column, i) => {
                              return (
                                this.isShowColumn(column.key) && (
                                  <th key={i} style={column.style ? column.style : {}} className={column.className || ''}>
                                    <span style={column.style ? column.style : {}}>{column.key}</span>
                                  </th>
                                )
                              );
                            })}
                            <th className="last"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {listLog.map((log, idx) => {
                            const isEditOnRow = action == ACTION_UPDATE && currentLog && log.id == currentLog.id && !log.isNew;
                            const isAddOnRow = action == ACTION_CREATE && currentLog && log.id == currentLog.id && log.isNew;
                            const isShowInputForm = currentLog && (isEditOnRow || isAddOnRow);
                            return (
                              <tr
                                key={idx}
                                style={{ position: 'relative' }}
                                className={`${idx == 0 ? ' first-row ' : ''}  ${idx == listLog.length - 1 ? ' last-row ' : ''} 
                            ${isEditOnRow ? ' current-log ' : isAddOnRow ? ' on-new-log ' : ''}
                            ${log.isError ? ' error ' : ''}
                            `}
                              >
                                {LOG_COLUMNS.map((column, i) => {
                                  return (
                                    this.isShowColumn(column.key) && (
                                      <td key={i} style={column.style ? column.style : {}} className={column.className || ''}>
                                        {column.type == 'json' || column.type == 'html' || column.render ? (
                                          column.render(log[column.key], {
                                            click: () => {
                                              this.showModalMoreDetail(log);
                                            },
                                          })
                                        ) : column.mergeColumn ? (
                                          <span style={column.style ? column.style : {}}>
                                            {column.mergeColumn.map((colMerge, iMerge) => {
                                              return (
                                                <span className="mr-2 d-block" key={iMerge}>
                                                  {log[colMerge]}
                                                </span>
                                              );
                                            })}
                                          </span>
                                        ) : (
                                          <span>{log[column.key]}</span>
                                        )}
                                      </td>
                                    )
                                  );
                                })}
                                <td>
                                  {isEditOnRow || isAddOnRow ? (
                                    <span className="log-action">
                                      <i
                                        className="fa fa-pencil pointer text-primary mr-2"
                                        onClick={(e) => {
                                          this.submitSaveEditOnOneRow();
                                        }}
                                      ></i>
                                      <i
                                        className="fa fa-close pointer"
                                        onClick={(e) => {
                                          this.closeEditOnOneRow(log, idx);
                                        }}
                                      ></i>
                                    </span>
                                  ) : (
                                    <span className="log-action">
                                      <Dropdown
                                        className="dotdotdot"
                                        trigger={'hover'}
                                        overlay={
                                          <Menu>
                                            {/* <Menu.Item key={1}>
                                              <span
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.onActionUpsertLog(ACTION_UPDATE, log, idx);
                                                }}
                                              >
                                                Edit
                                              </span>
                                            </Menu.Item> */}
                                            <Menu.Item key={2}>
                                              <span
                                                className="text-danger w-100"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.submitDeleteLog(log, idx);
                                                }}
                                              >
                                                Delete
                                              </span>
                                            </Menu.Item>
                                          </Menu>
                                        }
                                      >
                                        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                                          <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                        </a>
                                      </Dropdown>
                                    </span>
                                  )}
                                </td>
                                {/* <span style={{position:'absolute', right:0, top:0}}>
                              <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                              </span> */}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <Modal
            title={<span className="text font-20">Log</span>}
            // centered
            open={showModalLogDetail}
            onOk={() => {
              // this.closeAllModal();
              // this.submitUseTemplateAndConvertToTask();
              this.setState({ showModalLogDetail: false });
            }}
            onCancel={() => {
              this.setState({ showModalLogDetail: false });
            }}
            // cancelButtonProps={{ style: { display: "none" } }}
            width={800}
          >
            {currentLog &&
              Object.keys(currentLog || {}).map((k, i) => {
                return (
                  <div key={i} className="row mb-2">
                    <div className="col-2 text-left">{k}</div>
                    <div className="col-10 text-left">
                      {(k == 'payload' || k == 'response') && currentLog[k] ? <ReactJson src={parseJson(currentLog[k])} displayDataTypes={false} name={false} /> : currentLog[k]}
                    </div>
                  </div>
                );
              })}
          </Modal>
        </BlockUi>
      </Auxiliary>
    );
  }
}
